import React from "react";
import Topbar from "../Topbar/Topbar";
// import Footer from "../Footer/Footer";
import { Outlet } from "react-router-dom";
import NewFooter from "../../layouts/NewFooter/NewFooter";
import Footer from "../../components/home/Footer";

const MainLayout = ({ children }) => {
  return (
    <>
      <div className="overflow-y-auto overflow-x-hidden">
        <Topbar />
        <main>
          <div className="">
            <Outlet />
            {children}
          </div>
        </main>
        <Footer/>
        {/* <NewFooter /> */}
        {/* <Footer /> */}
      </div>
    </>
  );
};

export default MainLayout;
