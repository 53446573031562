import SignUp from "../pages/SignUp/SignUp";
import SignIn from "../pages/SignIn/SignIn";
import AccountSetup from "../pages/AccountSetup/AccountSetup";
import ForgotPassword from "../pages/ForgotPassword/ForgotPassword";
import Services from "../pages/Services/Services";
import MyBookings from "../pages/MyBookings/MyBookings";
import BookService from "../pages/BookService/BookService";
import BookingDetailView from "../pages/BookingDetailsView/BookingDetailView";
import Profile from "../pages/Profile/Profile";
import BookAppointment from "../pages/BookAppointment/BookAppointment";
import TermsAndConditions from "../pages/TermsAndConditions/TermsAndConditions";
import PrivacyPolicy from "../pages/PrivacyPolicy/PrivacyPolicy";
import ResetPassword from "../pages/ResetPassword/ResetPassword";
import Verification from "../pages/Verification/Verification";
import ForgetPassVerification from "../pages/ForgetPassVerification/ForgetPassVerification";
import Home from "../pages/Home/Home";
import ServiceRequestForm from "../pages/ServiceRequestForm/ServiceRequestForm";
import HowItWorks from "../pages/HowItWorks/HowItWorks";
import CostGuides from "../pages/CostGuides/CostGuides";
import Messages from "../pages/Messages/Messages";
import NewHome from "../pages/NewHome/NewHome";

export const authProtectedRoutes = [
  { path: "/bookyourservice/:id", components: <BookService /> },
  { path: "/servicesrequest/:id", components: <ServiceRequestForm /> },
  { path: "/mybooking", components: <MyBookings /> },
  { path: "/bookingdetails/:id", components: <BookingDetailView /> },
  { path: "/profile", components: <Profile /> },
  { path: "/bookappointment/:id", components: <BookAppointment /> },
  {
    path: "/termsandconditions",
    components: <TermsAndConditions />,
  },
  { path: "/privacypolicy", components: <PrivacyPolicy /> },
  //chat
  { path: "/messages", components: <Messages /> },
];

export const publicRoutes = [
  //   { path: "/", components: <Landing /> },
  { path: "/", components: <NewHome /> },
  { path: "/signin", components: <SignIn /> },
  { path: "/signup", components: <SignUp /> },
  { path: "/verification", components: <Verification /> },
  { path: "/accountsetup", components: <AccountSetup /> },
  { path: "/forgetpassword", components: <ForgotPassword /> },
  {
    path: "/forgotpassverification",
    components: <ForgetPassVerification />,
  },
  { path: "/resetpassword", components: <ResetPassword /> },
  { path: "/services", components: <Services /> },
  //   { path: "/howitwork", components: <HowItWork /> },
  //   { path: "/costguide", components: <CostGuide /> },
  { path: "/howitworks", components: <HowItWorks /> },
  { path: "/costguide", components: <CostGuides /> },
];
