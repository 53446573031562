import { Helmet } from "react-helmet";
import Header from "../../components/home/Header";
import NewFooter from "../../layouts/NewFooter/NewFooter";
import bannerImg from "../../assets/images/how-it-works/how-it-works-banner.png";
import step1img from "../../assets/images/how-it-works/step-1.webp";
import step2img from "../../assets/images/how-it-works/step-2.webp";
import step3img from "../../assets/images/how-it-works/step-3.webp";
import step4img from "../../assets/images/how-it-works/step-4.webp";
import step5img from "../../assets/images/how-it-works/step-5.webp";
import step6img from "../../assets/images/how-it-works/step-6.webp";
import NewHeader from "../../components/home/NewHeader";

const stepsData = [
  {
    id: 1,
    title: "Select a Category and then the service you require",
    description: `Once you have selected the service you require, let us know 
                  when you require the service requested, enter the description 
                  of the work along with any pictures or videos, if there is any 
                  additional information that you wish to share please add it with 
                  the description, and submit.`,
    imgSrc: step1img,
    imgAlt: "select the category image",
    reverse: false,
  },
  {
    id: 2,
    title: "Our Local Fixers will accept your job",
    description: `As soon as we have all the details for your job, we'll forward it 
                  on to relevant Fixers in your area. One of our fixers will promptly 
                  accept the job and you will get a confirmation that the job has been 
                  booked to your desired time and date.`,
    imgSrc: step2img,
    imgAlt: "local fixer image",
    reverse: true,
  },
  {
    id: 3,
    title: "Once they are on their way, you will be notified",
    description: `When our fixers are en route to your job, you will get a notification 
                  letting you know that our fixer is on the way to you. At that point, 
                  you will be able to track and communicate with our fixer.`,
    imgSrc: step3img,
    imgAlt: "notified image",
    reverse: false,
  },
  {
    id: 4,
    title: "On Arrival",
    description: `Upon the fixer's arrival, the timer will commence, enabling both you 
                  and the fixer to monitor the time and receive progress updates through 
                  your app.`,
    imgSrc: step4img,
    imgAlt: "arrival image",
    reverse: true,
  },
  {
    id: 5,
    title: "Once the service is fulfilled",
    description: `You will receive a notification through the app. The service provider 
                  will generate an invoice that includes any additional costs, such as 
                  materials provided or parking charges incurred. The invoice will be 
                  sent to you via the app for payment.`,
    imgSrc: step5img,
    imgAlt: "service fulfilled",
    reverse: false,
  },
  {
    id: 6,
    title: "Leave Feedback",
    description: `As soon as your job has been completed by the trade, we'd love it if you 
                  could leave them a review. These reviews are a great way to help our Fixers 
                  build their online reputations and they're also really useful for homeowners 
                  when adding them as favorite Fixers.`,
    imgSrc: step6img,
    imgAlt: "leave feedback image",
    reverse: true,
  },
];

const HowItWorks = () => (
  <>
    <Helmet>
      <title>How It Works - Go Fix It Now</title>
      <link rel="canonical" href="https://gofixitnow.online/howitworks" />
    </Helmet>
    {/* <Header /> */}
    <NewHeader />
    <div className="how-it-works-page">
      <section
        className="banner-section h-[50vh] bg-cover bg-center"
        style={{
          backgroundImage: `url(${bannerImg})`,
        }}
      >
        <div className="container relative flex justify-center items-center">
          <h1 className="heading">How It Works</h1>
        </div>
      </section>

      <section className="how-it-works-section">
        <div className="2xl:mx-32 xl:mx-20 lg:mx-14 md:mx-10 mx-4">
          {stepsData.map((step, index) => {
            return (
              <div
                className={`2xl:gap-6 xl:gap-6 gap-4 point w-full ${
                  step.reverse
                    ? "flex flex-row-reverse 2xl:flex-nowrap xl:flex-nowrap lg:flex-nowrap md:flex-nowrap flex-wrap items-center"
                    : "grid grid-cols-12 flex items-center"
                }`}
                key={index}
              >
                <div className="2xl:col-span-6 xl:col-span-6 lg:col-span-6 md:col-span-6 col-span-12 flex-col justify-center content-col">
                  <h3 className="text-[1.75rem] font-medium">{step.title}</h3>
                  <p className="">{step.description}</p>
                </div>
                <div className="2xl:col-span-6 xl:col-span-6 lg:col-span-6 md:col-span-6 col-span-12 image-col">
                  <div className="flex justify-center items-center">
                    <img
                      src={step.imgSrc}
                      alt={step.imgAlt}
                      className="main-image  2xl:w-[650px] 2xl:max-w-[650px] xl:w-[550px] xl:max-w-[500px] lg:w-[450px] lg:max-w-[450px] md:w-[340px] md:max-w-[340px] w-full h-full"
                    />
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </section>
    </div>
    <NewFooter />
  </>
);

export default HowItWorks;
