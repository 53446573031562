import { useState } from "react";
import "../../assets/css/calendar.css";
import { FiChevronLeft, FiChevronRight } from "react-icons/fi";

const CalendarAndTimePicker = ({ formik }) => {
  const [currentMonth, setCurrentMonth] = useState(new Date().getMonth());
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedTime, setSelectedTime] = useState(null);

  const getMonthName = (monthIndex) => {
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    return monthNames[monthIndex];
  };

  const generateCalendarDays = () => {
    const today = new Date();
    const firstDay = new Date(currentYear, currentMonth, 1).getDay();
    const daysInMonth = new Date(currentYear, currentMonth + 1, 0).getDate();

    const calendarDays = [];

    // Add empty cells for days before the first day of the month
    for (let i = 0; i < firstDay; i++) {
      calendarDays.push(<div key={`empty-${i}`} className="empty-cell"></div>);
    }

    // Add cells for each day of the month
    for (let day = 1; day <= daysInMonth; day++) {
      const dateCell = new Date(currentYear, currentMonth, day);
      const isPastDate = dateCell < today;
      const isSelected =
        selectedDate === `${currentYear}-${currentMonth + 1}-${day}`;

      calendarDays.push(
        <div
          key={day}
          className={`day-cell ${isPastDate ? "disabled" : ""} ${
            isSelected ? "selected" : ""
          }`}
          style={{ cursor: isPastDate ? "not-allowed" : "pointer" }}
          onClick={() => {
            if (!isPastDate) {
              setSelectedDate(`${currentYear}-${currentMonth + 1}-${day}`);
              formik.setFieldValue(
                "selectedDate",
                `${currentYear}-${currentMonth + 1}-${day}`
              );
            }
          }}
        >
          {day}
        </div>
      );
    }

    return calendarDays;
  };

  const generateTimeOptions = () => {
    const interval = 30; // minutes
    const timeOptions = [];

    for (let hour = 0; hour < 24; hour++) {
      for (let minute = 0; minute < 60; minute += interval) {
        const formattedHour = hour % 12 || 12;
        const formattedMinute = minute === 0 ? "00" : minute;
        const ampm = hour < 12 ? "AM" : "PM";
        const timeString = `${formattedHour}:${formattedMinute} ${ampm}`;
        const isSelected = selectedTime === timeString;

        timeOptions.push(
          <div
            key={timeString}
            className={`time-option ${isSelected ? "selected" : ""}`}
            onClick={() => {
              setSelectedTime(timeString);
              formik.setFieldValue("selectedTime", timeString);
            }}
          >
            {timeString}
          </div>
        );
      }
    }

    return timeOptions;
  };

  const isFirstMonth =
    currentMonth === new Date().getMonth() &&
    currentYear === new Date().getFullYear();

  function formatDate(dateString) {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  }
  return (
    <div className="flex 2xl:flex-row xl:flex-row lg:flex-row md:flex-row flex-column 2xl:justify-between xl:justify-between lg:justify-between md:justify-between">
      <div className="calendar">
        <div className="calendar-header">
          <button
            id="prev-month"
            type="button"
            onClick={() => {
              if (currentMonth === 0) {
                setCurrentMonth(11);
                setCurrentYear(currentYear - 1);
              } else {
                setCurrentMonth(currentMonth - 1);
              }
            }}
            disabled={isFirstMonth}
            className={isFirstMonth ? "disabled" : ""}
          >
            <FiChevronLeft color="#FFFFFF" fontSize={22} />
          </button>
          <h3 id="month-year">
            {getMonthName(currentMonth)} {currentYear}
          </h3>
          <button
            id="next-month"
            type="button"
            onClick={() => {
              if (currentMonth === 11) {
                setCurrentMonth(0);
                setCurrentYear(currentYear + 1);
              } else {
                setCurrentMonth(currentMonth + 1);
              }
            }}
          >
            <FiChevronRight color="#FFFFFF" fontSize={22} />
          </button>
        </div>
        <div className="day-names">
          <div>Sun</div>
          <div>Mon</div>
          <div>Tue</div>
          <div>Wed</div>
          <div>Thu</div>
          <div>Fri</div>
          <div>Sat</div>
        </div>
        <div className="calendar-grid">{generateCalendarDays()}</div>
        <div className="selected-info">
          <p
            id="selected-date"
            className={`2xl:mb-2 xl:mb-2 lg:mb-2 md:mb-2 mb-1 px-3 py-1.5 ${
              formik.touched.selectedDate && formik.errors.selectedDate
                ? "invalid"
                : ""
            }`}
          >
            Selected Date: {selectedDate ? formatDate(selectedDate) : "None"}
          </p>
          <p
            id="selected-time"
            className={`2xl:mb-2 xl:mb-2 lg:mb-2 md:mb-2 mb-1 px-3 py-1.5 ${
              formik.touched.selectedTime && formik.errors.selectedTime
                ? "invalid"
                : ""
            }`}
          >
            Selected Time: {selectedTime || "None"}
          </p>
        </div>
      </div>
      <div className="time-picker">{generateTimeOptions()}</div>
    </div>
  );
};

export default CalendarAndTimePicker;
