import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";

const firebaseConfig = {
  apiKey: "AIzaSyCrVLbKI6ePNf-_e4ykY7aAwLlUULLjXrE",
  authDomain: "gofixitnow-e5d5e-f66c7.firebaseapp.com",
  projectId: "gofixitnow-e5d5e",
  storageBucket: "gofixitnow-e5d5e.appspot.com",
  messagingSenderId: "330690486729",
  appId: "1:330690486729:web:dcfd4bff2761ef47c3c818",
  measurementId: "G-8RFWSDBM9G",
};

const app = initializeApp(firebaseConfig);
const messaging = getMessaging(app);

export { messaging, getToken, onMessage };
