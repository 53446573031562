import React, { useEffect, useState } from "react";
import clsx from "clsx";
import {
  getChatListApi,
  getChatMessageListApi,
  sendMesageApi,
} from "../../services/ApiServices";
import { IMAGE_URL } from "../../services/Api";
import noImg from "../../assets/images/no-image.png";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { Socket } from "../../Socket/Socket";
import moment from "moment/moment";
import { FiPaperclip } from "react-icons/fi";
import sendIcon from "../../assets/images/paper-plane.png";
import toast from "react-hot-toast";
import { HiOutlinePhoto } from "react-icons/hi2";
import InfiniteScroll from "react-infinite-scroll-component";
import { ThreeDots } from "react-loader-spinner";
import { useLocation } from "react-router-dom";

const Messages = () => {
  const location = useLocation();
  const user_info = JSON.parse(localStorage.getItem("user_info"));
  const [isChatOpen, setIsChatOpen] = useState(false);

  //chat list
  const [pageNo, setPageNo] = useState(1);
  const [chatList, setChatList] = useState([]);
  const [loader, setLoader] = useState(false);
  const [isStopPagination, setIsStopPagination] = useState(false);
  const [noList, setNoList] = useState(false);
  const [hasMoreList, setHasMoreList] = useState(true);

  //chat message list
  const [currentIndex, setCurrentIndex] = useState("");
  const [otherId, setOtherId] = useState("");
  const [chatId, setChatId] = useState("");
  const [chatMessages, setChatMessages] = useState([]);

  //pagination
  const [currPage, setCurrPage] = useState(1);
  const [noData, setNodata] = useState(false);
  const [hasMoreData, setHasMoreData] = useState(true);
  const [totalPage, setTotalPage] = useState(0);

  //send message
  const [formValues, setFormValues] = useState({});
  const [isSending, setIsSending] = useState(false);

  const [isDirectOpen, setIsDirectOpen] = useState();
  const datas = location?.state?.Data || "";

  const openChat = (data) => {
    setIsDirectOpen(2);
    setIsChatOpen(true);
    setChatId(data.chat_id);
    setOtherId(data.other_id);
    setTotalPage(0);
    setChatMessages([]);
    setCurrPage(1);

    const chat_info = {
      chat_id: data.chat_id,
      user_id: data.other_id,
    };
    getChatMessages(data.chat_id, 1);
    localStorage.setItem("fixer_chat_info", JSON.stringify(chat_info));

    Socket.emit("join_room", {
      user_id: data.other_id,
      chat_id: data.chat_id,
    });

    if (data.chat_id != chatId) {
      if (chatId) {
        Socket.emit("left_room", {
          user_id: otherId,
          chat_id: chatId,
        });
      }

      Socket.emit("join_room", {
        user_id: data.other_id,
        chat_id: data.chat_id,
      });
    }
  };

  useEffect(() => {
    if (datas) {
      openChat(datas);
    }
  }, [datas]);

  useEffect(() => {
    Socket.on("count_update", (data) => updatecount(data));

    Socket.on("new_message", (data) => {
      handleNewMessage(data);
    });
  }, [Socket, chatMessages]);

  const handleNewMessage = (data) => {
    if (chatId) {
      if (chatId === data?.chat_id) {
        setChatMessages([data, ...chatMessages]);
      } else {
        setChatMessages([...chatMessages]);
      }
    }
  };

  const updatecount = (datas) => {
    let is_update = 0;

    console.log("datas >>>", datas);

    setChatList((prevChats) => {
      const updatedChats = prevChats.map((item) => {
        if (item.chat_id === datas.chat_id) {
          is_update = 1;
          return datas;
        }
        return item;
      });
      return is_update ? updatedChats : [datas, ...prevChats];
    });
  };

  const getChatList = (page_no) => {
    setNoList(true);

    getChatListApi(page_no)
      .then((res) => {
        const info = res.data.data;
        const status = res.data.status;

        if (status === 1) {
          if (info.length == 0) {
            setIsStopPagination(false);
          } else {
            setChatList((prevList) =>
              page_no === 1 ? info : [...prevList, ...info]
            );
            setPageNo(page_no);
            setIsStopPagination(false);
          }
        } else {
          setChatList([]);
        }
        setNoList(false);
        setLoader(false);
      })
      .catch((err) => {
        setLoader(false);
        setNoList(false);
        console.log("err >>>", err);
      });
  };

  useEffect(() => {
    setLoader(true);
    getChatList(pageNo);
  }, []);

  const viewMore = () => {
    if (!isStopPagination) {
      getChatList(pageNo + 1);
      setHasMoreList(true);
    } else {
      setHasMoreList(false);
    }
  };

  const handleInput = (e) => {
    const { name, value } = e.target;

    if (value.trim() !== "" || value === "") {
      setFormValues({
        ...formValues,
        [name]: value,
      });
    }
  };

  const handleOpenChat = (e, item, index) => {
    setIsDirectOpen(1);
    if (item.chat_id != chatId) {
      if (chatId) {
        Socket.emit("left_room", {
          user_id: otherId,
          chat_id: chatId,
        });
      }

      Socket.emit("join_room", {
        user_id: item.other_id,
        chat_id: item.chat_id,
      });
    }

    setIsChatOpen(true);
    setChatId(item.chat_id);
    setOtherId(item.other_id);
    setCurrentIndex(index);
    setTotalPage(0);
    setChatMessages([]);
    setCurrPage(1);

    const chat_info = {
      chat_id: item.chat_id,
      user_id: item.other_id,
    };
    getChatMessages(item.chat_id, 1);
    localStorage.setItem("user_chat_info", JSON.stringify(chat_info));

    let updatedMessages = [...chatList];
    updatedMessages[index].unread_count = 0;
    setChatList(updatedMessages);
  };

  const getChatMessages = (chat_id, page_no) => {
    setNodata(true);

    getChatMessageListApi(page_no, chat_id)
      .then((res) => {
        const data = res?.data?.data;
        const status = res.data.status;
        const total_page = res.data.totalPages;

        if (status === 1) {
          if (page_no != 1) {
            setChatMessages((pre) => [...pre, ...data]);
          } else {
            setChatMessages(data);
          }
          setCurrPage(page_no);
          setNodata(false);
          setTotalPage(total_page);
        }
      })
      .catch((err) => {
        setNodata(false);
        console.log("err >>>", err);
      });
  };

  const handleScroll1 = () => {
    if (totalPage !== currPage) {
      getChatMessages(chatId, currPage + 1);
      setHasMoreData(true);
    } else {
      setHasMoreData(false);
    }
  };

  const handleImageUpload = (e, message_type) => {
    const { files } = e.target;
    setIsSending(true);

    var formdata = new FormData();
    formdata.append("other_id", otherId);
    formdata.append("chat_id", chatId);
    formdata.append("media", files[0]);
    formdata.append("type", "image");

    sendMesageApi(formdata)
      .then((res) => {
        const status = res.data.Status;
        const message = res.data.Message;

        if (status === 0) {
          toast.error(message || "Smothing went wrong!!");
        } else {
          setIsSending(false);
          setFormValues({ message_text: "" });
        }
      })
      .catch((err) => {
        setIsSending(false);
        console.log("err >>>", err);
      });
  };

  const handleSendMessage = (e) => {
    e.preventDefault();
    setIsSending(true);

    var formdata = new FormData();
    formdata.append("other_id", otherId);
    formdata.append("chat_id", chatId);
    formdata.append("message_type", "Text");
    formdata.append("message_text", formValues.message_text);

    sendMesageApi(formdata)
      .then((res) => {
        setIsSending(false);
        setFormValues({ message_text: "" });
      })
      .catch((err) => {
        setIsSending(false);
        console.log("err >>>", err);
      });
  };

  return (
    <main className="bg-[#f1f5f9]">
      <section className="2xl:mt-24 xl:mt-24 lg:mt-22 mt-20">
        <div className="2xl:mx-32 xl:mx-20 lg:mx-14 md:mx-10 mx-4">
          <div className="flex flex-col items-center py-6 intro-y sm:flex-row">
            <h2 className="mr-auto text-lg font-medium">Messages</h2>
          </div>
          <div className="grid grid-cols-12 gap-5 pb-8 intro-y">
            <div className="col-span-12 lg:col-span-4 xl:col-span-4 2xl:col-span-3">
              {loader == true ? (
                <div className="flex justify-center items-center col-span-12">
                  <SkeletonTheme
                    baseColor="#cccccc"
                    highlightColor="#e0e0e0"
                    borderRadius="0.5rem"
                    duration={1.5}
                  >
                    <div className="grid-cols-12 grid w-full">
                      {[1, 2, 3, 4, 5, 6, 7].map((star) => (
                        <div
                          key={star}
                          className="col-span-12 w-full h-[90px] bg-gray-300 rounded-[12px] p-[14px] my-[5px]"
                        >
                          <Skeleton width="100%" />
                        </div>
                      ))}
                    </div>
                  </SkeletonTheme>
                </div>
              ) : (
                <>
                  {chatList.length === 0 ? (
                    <div className="2xl:h-[700px] xl:h-[740px] h-[650px] flex justify-center items-center bg-white">
                      No Chat Found
                    </div>
                  ) : (
                    <div
                      className="overflow-y-scroll scrollbar-hide 2xl:h-[750px] h-[740px] pt-5 px-2"
                      id="chatList"
                    >
                      {chatList.map((item, index) => (
                        <div
                          key={index}
                          className={clsx({
                            "intro-x cursor-pointer box relative flex justify-between p-4": true,
                            "mt-5": index,
                          })}
                          onClick={(e) => handleOpenChat(e, item, index)}
                        >
                          <div className="flex items-center">
                            <div className="flex-none w-12 h-12 mr-1 image-fit">
                              <img
                                alt="user-profile"
                                className="rounded-full h-full w-full object-center object-cover"
                                src={
                                  item.profilePic
                                    ? IMAGE_URL + item.profilePic
                                    : noImg
                                }
                                crossOrigin="anonymous"
                              />
                            </div>
                            <div className="ml-2 overflow-hidden">
                              <div className="flex items-center mb-1">
                                <h6 className="font-medium text-sm">
                                  {`${item.firstName} ${item.lastName}`} #
                                  {item.bookingId}
                                </h6>
                              </div>
                              {item.message_type === "Text" ? (
                                <div className="2xl:w-44 xl:w-44 truncate text-[#64748b] mt-0.5 text-sm">
                                  {item.message_text}
                                </div>
                              ) : item.message_type === "Image" ? (
                                <div className="flex items-center gap-1">
                                  <HiOutlinePhoto
                                    fontSize={20}
                                    color="#262626"
                                  />
                                  <h3 className="text-[#262626] text-sm">
                                    Photo
                                  </h3>
                                </div>
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                          <div className="ml-auto text-xs text-slate-400">
                            {moment(item.createdAt).fromNow()}
                          </div>
                          {item.unread_count ? (
                            <div className="absolute top-0 right-0 flex items-center justify-center w-5 h-5 -mt-1 -mr-1 text-xs font-medium text-white rounded-full bg-primary">
                              {item.unread_count}
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      ))}
                      <div className="flex justify-center items-center my-5">
                        <InfiniteScroll
                          dataLength={chatList.length || 0}
                          next={viewMore}
                          hasMore={hasMoreList}
                          loader={
                            noList ? (
                              <ThreeDots
                                width={40}
                                height={10}
                                color="#2b426e"
                                ariaLabel="circles-loading"
                                wrapperStyle={{}}
                                visible={true}
                                wrapperClass=""
                              />
                            ) : (
                              ""
                            )
                          }
                          scrollableTarget="chatList"
                        ></InfiniteScroll>
                      </div>
                    </div>
                  )}
                </>
              )}
            </div>
            <div className="col-span-12 intro-y lg:col-span-8 xl:col-span-8 2xl:col-span-9">
              <div className="h-[770px] box">
                {isChatOpen && (
                  <div className="flex flex-col h-full">
                    {isDirectOpen === 1 ? (
                      <div className="flex flex-col px-5 py-4 border-b sm:flex-row border-[#e2e8f099]">
                        <div className="flex items-center">
                          <div className="relative flex-none w-14 h-14 image-fit">
                            <img
                              alt="profile-pic"
                              className="rounded-full w-full h-full object-center object-cover"
                              src={
                                chatList[currentIndex].profilePic
                                  ? IMAGE_URL +
                                    chatList[currentIndex].profilePic
                                  : noImg
                              }
                              crossOrigin="anonymous"
                            />
                          </div>
                          <div className="ml-3 mr-auto">
                            <div className="text-base font-medium">
                              {`${chatList[currentIndex].firstName} ${chatList[currentIndex].lastName} (${chatList[currentIndex].bookingId})` ||
                                " "}
                            </div>
                            <h5 className="text-[12px] w-full text-[#64748b]">
                              Booking Id #{chatList[currentIndex].bookingId}
                            </h5>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div className="flex flex-col px-5 py-4 border-b sm:flex-row border-[#e2e8f099]">
                        <div className="flex items-center">
                          <div className="relative flex-none w-14 h-14 image-fit">
                            <img
                              alt="profile-pic"
                              className="rounded-full w-full h-full object-center object-cover"
                              src={
                                datas.profilePic
                                  ? IMAGE_URL + datas.profilePic
                                  : noImg
                              }
                              crossOrigin="anonymous"
                            />
                          </div>
                          <div className="ml-3 mr-auto">
                            <div className="text-base font-medium">
                              {`${datas.firstName} ${datas.lastName}` || " "}
                            </div>
                            <h5 className="text-[12px] w-full text-[#64748b]">
                              Booking Id #{datas?.bookingId}
                            </h5>
                          </div>
                        </div>
                      </div>
                    )}
                    <div
                      className="overflow-y-scroll scrollbar-hide h-full px-5 pt-5"
                      id="chatdiv"
                      style={{
                        overflowY: "scroll",
                        display: "flex",
                        flexDirection: "column-reverse",
                      }}
                    >
                      {chatMessages.length <= 0 && !noData ? (
                        <div className="text-center justify-center items-center flex h-full">
                          <h4>No data found</h4>
                        </div>
                      ) : (
                        <>
                          {chatMessages.map((item, index) => {
                            return (
                              <div key={index}>
                                {user_info.userId != item?.message_from ? (
                                  <>
                                    <div className="flex items-center float-left mb-4 max-w-[90%] sm:max-w-[49%]">
                                      <div className="relative flex-none w-10 h-10 mr-5 sm:block image-fit">
                                        {isDirectOpen === 1 ? (
                                          <img
                                            alt="profile"
                                            className="rounded-full object-cover object-center h-full w-full"
                                            src={
                                              chatList[currentIndex].profilePic
                                                ? IMAGE_URL +
                                                  chatList[currentIndex]
                                                    .profilePic
                                                : noImg
                                            }
                                            crossOrigin="anonymous"
                                          />
                                        ) : (
                                          <img
                                            alt="profile"
                                            className="rounded-full object-cover object-center h-full w-full"
                                            src={
                                              datas?.profilePic
                                                ? IMAGE_URL + datas?.profilePic
                                                : noImg
                                            }
                                            crossOrigin="anonymous"
                                          />
                                        )}
                                      </div>
                                      {item.message_type === "Text" ? (
                                        <div className="text-start">
                                          <div className="text-start px-4 py-2 bg-[#f1f5f9] text-[#64748b] rounded-r-md rounded-t-md text-sm">
                                            {item.message_text || ""}
                                          </div>
                                          <div className="mt-1 text-[10px] w-full text-[#64748b]">
                                            {moment(item.createdAt).format(
                                              "h:mm A"
                                            )}
                                          </div>
                                        </div>
                                      ) : (
                                        <div className="text-start">
                                          <div className=" px-4 py-3 bg-[#f1f5f9] text-[#64748b] rounded-r-md rounded-t-md">
                                            <div className="w-44 h-44 py-2">
                                              <img
                                                src={
                                                  item.message_text
                                                    ? IMAGE_URL +
                                                      item.message_text
                                                    : noImg
                                                }
                                                alt=""
                                                className="rounded-md w-full h-full object-cover object-center"
                                                crossOrigin="anonymous"
                                              ></img>
                                            </div>
                                          </div>
                                          <div className="mt-1 text-[10px] text-start w-full text-[#64748b]">
                                            {moment(item.createdAt).format(
                                              "h:mm A"
                                            )}
                                          </div>
                                        </div>
                                      )}
                                    </div>
                                    <div className="clear-both"></div>
                                  </>
                                ) : (
                                  <>
                                    <div className="float-right mb-4 max-w-[90%] sm:max-w-[49%]">
                                      <div className="px-4 py-2 text-white bg-primary rounded-l-md rounded-t-md">
                                        {item.message_type === "Text" ? (
                                          <div className="text-sm">
                                            {item.message_text || ""}
                                          </div>
                                        ) : (
                                          <div className="w-44 h-44 py-2">
                                            <img
                                              src={
                                                item.message_text
                                                  ? IMAGE_URL +
                                                    item.message_text
                                                  : noImg
                                              }
                                              alt=""
                                              className="rounded-md w-full h-full object-cover object-center"
                                              crossOrigin="anonymous"
                                            ></img>
                                          </div>
                                        )}
                                      </div>
                                      <div className="text-end mt-1 text-[10px] text-black text-opacity-80">
                                        {moment(item.createdAt).format(
                                          "h:mm A"
                                        )}
                                      </div>
                                    </div>
                                    <div className="clear-both"></div>
                                  </>
                                )}
                              </div>
                            );
                          })}
                        </>
                      )}
                      <div className="flex justify-center items-center h-full">
                        <InfiniteScroll
                          dataLength={chatMessages.length || 0}
                          next={handleScroll1}
                          inverse={true}
                          hasMore={hasMoreData}
                          loader={
                            noData ? (
                              <ThreeDots
                                width={40}
                                height={10}
                                color="#2b426e"
                                ariaLabel="circles-loading"
                                wrapperStyle={{}}
                                visible={true}
                                wrapperClass=""
                              />
                            ) : (
                              ""
                            )
                          }
                          scrollableTarget="chatdiv"
                        ></InfiniteScroll>
                      </div>
                    </div>
                    {chatList[currentIndex]?.bookingStatus !== 2 &&
                    isDirectOpen === 1 ? (
                      <form onSubmit={handleSendMessage}>
                        <div className="flex items-center pt-4 pb-6 px-2 gap-2 border-t sm:py-4 border-slate-200/60 dark:border-darkmode-400">
                          <input
                            type="text"
                            className="bg-white rounded-lg w-full py-2 px-4 outline-none"
                            placeholder="Type your message..."
                            onChange={(e) => handleInput(e, 1)}
                            value={formValues.message_text || ""}
                            name="message_text"
                            autoComplete="off"
                            required
                          />
                          <div
                            className={`shadow-md relative flex items-center justify-center flex-none rounded-full bg-pink p-2 ${
                              formValues.message_text ? "mr-0" : "mr-5"
                            } w-10 h-10`}
                          >
                            <label htmlFor="upload1">
                              <FiPaperclip className="w-4 h-4" />
                            </label>
                            <input
                              id="upload1"
                              type="file"
                              onChange={(e) => handleImageUpload(e, 1)}
                              name="file"
                              className="absolute w-full opacity-0"
                              accept="image/*"
                            />
                          </div>
                          {formValues.message_text ? (
                            <button
                              type="submit"
                              className="shadow-md flex items-center justify-center flex-none p-3 text-white rounded-full sm:w-10 sm:h-10 bg-white"
                              disabled={isSending}
                            >
                              <img src={sendIcon} alt="" className="w-4 h-4" />
                            </button>
                          ) : (
                            ""
                          )}
                        </div>
                      </form>
                    ) : datas.bookingStatus !== 2 && isDirectOpen === 2 ? (
                      <form onSubmit={handleSendMessage}>
                        <div className="flex items-center pt-4 pb-6 px-2 gap-2 border-t sm:py-4 border-slate-200/60 dark:border-darkmode-400">
                          <input
                            type="text"
                            className="bg-white rounded-lg w-full py-2 px-4 outline-none"
                            placeholder="Type your message..."
                            onChange={(e) => handleInput(e, 1)}
                            value={formValues.message_text || ""}
                            name="message_text"
                            autoComplete="off"
                            required
                          />
                          <div
                            className={`shadow-md relative flex items-center justify-center flex-none rounded-full bg-pink p-2 ${
                              formValues.message_text ? "mr-0" : "mr-5"
                            } w-10 h-10`}
                          >
                            <label htmlFor="upload1">
                              <FiPaperclip className="w-4 h-4" />
                            </label>
                            <input
                              id="upload1"
                              type="file"
                              onChange={(e) => handleImageUpload(e, 1)}
                              name="file"
                              className="absolute w-full opacity-0"
                              accept="image/*"
                            />
                          </div>
                          {formValues.message_text ? (
                            <button
                              type="submit"
                              className="shadow-md flex items-center justify-center flex-none p-3 text-white rounded-full sm:w-10 sm:h-10 bg-white"
                              disabled={isSending}
                            >
                              <img src={sendIcon} alt="" className="w-4 h-4" />
                            </button>
                          ) : (
                            ""
                          )}
                        </div>
                      </form>
                    ) : (
                      ""
                    )}
                  </div>
                )}
                {!isChatOpen && (
                  <div className="flex items-center h-full">
                    <div className="mx-auto text-center">
                      <div className="flex-none w-16 h-16 mx-auto overflow-hidden rounded-full image-fit">
                        <img
                          alt="user-profile"
                          src={
                            user_info.profilePic
                              ? IMAGE_URL + user_info.profilePic
                              : noImg
                          }
                          crossOrigin="anonymous"
                          className="w-full h-full object-cover object-center"
                        />
                      </div>
                      <div className="mt-3">
                        <div className="font-medium">Hey!!</div>
                        <div className="mt-1 text-[#64748b]">
                          Please select a chat to start messaging.
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
};

export default Messages;
