import React, { Fragment, useEffect, useRef, useState } from "react";
import NewHeader from "../../components/home/NewHeader";
import bg1 from "../../assets/images/home/bg-1.png";
import { Disclosure, Transition } from "@headlessui/react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import TopBar from "../../layouts/Topbar/Topbar";
import { ChevronDownIcon } from "@heroicons/react/24/outline";
import c1 from "../../assets/images/home/category/c1.png";
import c2 from "../../assets/images/home/category/c2.png";
import c3 from "../../assets/images/home/category/c3.png";
import c4 from "../../assets/images/home/category/c4.png";
import c5 from "../../assets/images/home/category/c5.png";
import c6 from "../../assets/images/home/category/c6.png";
import c7 from "../../assets/images/home/category/c7.png";
import c8 from "../../assets/images/home/category/c8.png";
import c9 from "../../assets/images/home/category/c9.png";
import s1 from "../../assets/images/home/services/s1.png";
import s2 from "../../assets/images/home/services/s2.png";
import s3 from "../../assets/images/home/services/s3.png";
import s4 from "../../assets/images/home/services/s4.png";
import s5 from "../../assets/images/home/services/s5.png";
import s6 from "../../assets/images/home/services/s6.png";
import s7 from "../../assets/images/home/services/s7.png";
import s8 from "../../assets/images/home/services/s8.png";
import s9 from "../../assets/images/home/services/s9.png";
import s10 from "../../assets/images/home/services/s10.png";
import s11 from "../../assets/images/home/services/s11.png";
import checkIcon from "../../assets/images/home/check.png";
import q1 from "../../assets/images/home/q1.png";
import q2 from "../../assets/images/home/q2.png";
import q3 from "../../assets/images/home/q3.png";
import q4 from "../../assets/images/home/q4.png";
import sImg1 from "../../assets/images/home/serviceimg/s1.png";
import sImg2 from "../../assets/images/home/serviceimg/s2.png";
import sImg3 from "../../assets/images/home/serviceimg/s3.png";
import sImg4 from "../../assets/images/home/serviceimg/s4.png";
import sImg5 from "../../assets/images/home/serviceimg/s5.png";
import sImg6 from "../../assets/images/home/serviceimg/s6.png";
import sImg7 from "../../assets/images/home/serviceimg/s7.png";
import sImg8 from "../../assets/images/home/serviceimg/s8.png";
import sImg9 from "../../assets/images/home/serviceimg/s9.png";
import service1 from "../../assets/images/home/popularservices/service1.png";
import service2 from "../../assets/images/home/popularservices/service2.png";
import service3 from "../../assets/images/home/popularservices/service3.png";
import service4 from "../../assets/images/home/popularservices/service4.png";
import whyChoose from "../../assets/images/home/why-choose-img.png";
import img1 from "../../assets/images/home/img1.png";
import img2 from "../../assets/images/home/img2.png";
import img3 from "../../assets/images/home/img3.png";
import img4 from "../../assets/images/home/img4.png";
import icon1 from "../../assets/images/home/mobile.png";
import icon2 from "../../assets/images/home/client.png";
import icon3 from "../../assets/images/home/users.png";
import profile1 from "../../assets/images/home/profile1.png";
import profile2 from "../../assets/images/home/profile2.png";
import areaBg from "../../assets/images/home/area-bg.png";
import area1 from "../../assets/images/home/areas/area1.png";
import area2 from "../../assets/images/home/areas/area2.png";
import area3 from "../../assets/images/home/areas/area3.png";
import area4 from "../../assets/images/home/areas/area4.png";
import area5 from "../../assets/images/home/areas/area5.png";
import area6 from "../../assets/images/home/areas/area6.png";
import area7 from "../../assets/images/home/areas/area7.png";
import area8 from "../../assets/images/home/areas/area8.png";
import area9 from "../../assets/images/home/areas/area9.png";
import area10 from "../../assets/images/home/areas/area10.png";
import area11 from "../../assets/images/home/areas/area11.png";
import area12 from "../../assets/images/home/areas/area12.png";
import area13 from "../../assets/images/home/areas/area13.png";
import area14 from "../../assets/images/home/areas/area14.png";
import area15 from "../../assets/images/home/areas/area15.png";
import area16 from "../../assets/images/home/areas/area16.png";
import area17 from "../../assets/images/home/areas/area17.png";
import area18 from "../../assets/images/home/areas/area18.png";
import area19 from "../../assets/images/home/areas/area19.png";
import area20 from "../../assets/images/home/areas/area20.png";
import area21 from "../../assets/images/home/areas/area21.png";
import area22 from "../../assets/images/home/areas/area22.png";
import area23 from "../../assets/images/home/areas/area23.png";
import area24 from "../../assets/images/home/areas/area24.png";
import area25 from "../../assets/images/home/areas/area25.png";
import area26 from "../../assets/images/home/areas/area26.png";
import area27 from "../../assets/images/home/areas/area27.png";
import area28 from "../../assets/images/home/areas/area28.png";
import { GrFormNext, GrFormPrevious } from "react-icons/gr";
import Footer from "../../components/home/Footer";
import { useNavigate } from "react-router-dom";
import { ChevronUpIcon, MoveUpRight, TrendingUp } from "lucide-react";
import s1c1 from "../../assets/images/home/homecareassistant/companianship.png";
import s1c2 from "../../assets/images/home/homecareassistant/domesticcare.png";
import s1c3 from "../../assets/images/home/homecareassistant/medication.png";
import s1c4 from "../../assets/images/home/homecareassistant/mobilityhelp.png";
import s1c5 from "../../assets/images/home/homecareassistant/personalcare.png";
import s1c6 from "../../assets/images/home/homecareassistant/prepairmeals.png";

const category_arr = [
  {
    serviceId: 1,
    serviceName: "Handyman Services",
    serviceIcon: c1,
  },
  {
    serviceId: 2,
    serviceName: "Home Care Services",
    serviceIcon: c2,
  },
  {
    serviceId: 3,
    serviceName: "Electrical Services",
    serviceIcon: c3,
  },
  {
    serviceId: 4,
    serviceName: "Plumbing Services",
    serviceIcon: c4,
  },
  {
    serviceId: 5,
    serviceName: "Painting & Decorating",
    serviceIcon: c5,
  },
  {
    serviceId: 6,
    serviceName: "Gardening Service",
    serviceIcon: c6,
  },
  {
    serviceId: 7,
    serviceName: "Cleaning Services",
    serviceIcon: c7,
  },
  {
    serviceId: 8,
    serviceName: "Waste Removal",
    serviceIcon: c8,
  },
  {
    serviceId: 9,
    serviceName: "Transporft & Moving",
    serviceIcon: c9,
  },
];

const services_arr = [
  {
    serviceId: 1,
    serviceName: "Flatpack Assembly",
    serviceIcon: s1,
  },
  {
    serviceId: 2,
    serviceName: "Home Repairs",
    serviceIcon: s2,
  },
  {
    serviceId: 3,
    serviceName: "Tv Mounting",
    serviceIcon: s3,
  },
  {
    serviceId: 4,
    serviceName: "Shelving",
    serviceIcon: s4,
  },
  {
    serviceId: 5,
    serviceName: "Curtains & Blinds",
    serviceIcon: s5,
  },
  {
    serviceId: 6,
    serviceName: "Flooring",
    serviceIcon: s6,
  },
  {
    serviceId: 7,
    serviceName: "Tiling",
    serviceIcon: s7,
  },
  {
    serviceId: 8,
    serviceName: "Hanging Doors",
    serviceIcon: s8,
  },
  {
    serviceId: 9,
    serviceName: "Kitchen Repair",
    serviceIcon: s9,
  },
  {
    serviceId: 10,
    serviceName: "Carpentry",
    serviceIcon: s10,
  },
  {
    serviceId: 11,
    serviceName: "Dry Wall & Plastering",
    serviceIcon: s11,
  },
  {
    serviceId: 12,
    serviceName: "Something Else",
    serviceIcon: null,
  },
];

const serviceimg_arr = [
  {
    serviceId: 1,
    serviceName: "Handyman Services",
    serviceImg: sImg1,
    description:
      "Experience exceptional flatpack assembly, TV mounting, and flooring installation services with a commitment to quality and customer satisfaction. Schedule your service today!",
  },
  {
    serviceId: 2,
    serviceName: "Home Care Assistant",
    serviceImg: sImg2,
    description:
      "Experience personalized home care that prioritizes your safety and happiness at home. We handle everything from tucking you in and washing dishes to ironing clothes and meeting your shopping needs.",
  },
  {
    serviceId: 3,
    serviceName: "Electrical Services",
    serviceImg: sImg3,
    description:
      "Our electricians provide friendly installation and repair services, including electric car charging points, and conduct safety inspections for landlords.",
  },
  {
    serviceId: 4,
    serviceName: "Plumbing Services",
    serviceImg: sImg4,
    description:
      "Are you in need of plumbing services? Whether you are dealing with burst pipes or blocked drains, Taskbudy is here to offer effective solutions. Contact us today!",
  },
  {
    serviceId: 5,
    serviceName: "Painting & Decorating",
    serviceImg: sImg5,
    description:
      "Transform your space with Taskbudy, your go-to for expert painting and wallpapering. Experience quality workmanship and personalized service to create your dream atmosphere effortlessly!",
  },
  {
    serviceId: 6,
    serviceName: "Gardening Services",
    serviceImg: sImg6,
    description:
      "Gardening offers relaxation and therapeutic benefits, particularly as we age. Regular visits from the same gardener create a welcoming environment for conversation and assistance with seasonal tasks.",
  },
  {
    serviceId: 7,
    serviceName: "Cleaning Services",
    serviceImg: sImg7,
    description:
      "A same-day cleaning service provides quick cleaning for urgent needs, perfect for surprise guests. Trained professionals refresh your space, saving time and enhancing the environment.",
  },
  {
    serviceId: 8,
    serviceName: "Waste Removal",
    serviceImg: sImg8,
    description:
      "Taskbuddy Waste Removals makes junk removal easy—just book via the app, and a pro will take care of everything in under an hour. Enjoy clear pricing with no permits or advance bookings needed!",
  },
  {
    serviceId: 9,
    serviceName: "Transportation & Moving",
    serviceImg: sImg9,
    description:
      "Taskbuddy simplifies relocation with services for residential and specialized moves, ensuring a quick and efficient experience through our reliable man-andvan service.",
  },
];

const popular_service_arr = [
  {
    serviceId: 1,
    serviceName: "Flatpack Assembly",
    price: "From £30",
    serviceImg: service1,
  },
  {
    serviceId: 2,
    serviceName: "TV mounting",
    price: "From £30",
    serviceImg: service2,
  },
  {
    serviceId: 3,
    serviceName: "Install A EV Charger",
    price: "From £280",
    serviceImg: service3,
  },
  {
    serviceId: 4,
    serviceName: "Boiler Repairs",
    price: "From £50",
    serviceImg: service4,
  },
];

const statsData = [
  {
    imgSrc: icon1,
    label: "Total Orders",
    value: "2018002",
  },
  {
    imgSrc: icon2,
    label: "Active Clients",
    value: "718002",
  },
  {
    imgSrc: icon3,
    label: "Team Members",
    value: "21089",
  },
];

const review_arr = [
  {
    id: 1,
    profilePic: profile1,
    firstName: "Mark",
    lastName: "Tomson",
    reviewStar: 3,
    reviewText:
      "Fantastic. Exceptionally efficient and effective with all the jobs done to a very high standard. He was also very friendly.",
  },
  {
    id: 2,
    profilePic: profile2,
    firstName: "Jason",
    lastName: "Smith",
    reviewStar: 4,
    reviewText:
      "did a great job. He was efficient, quick and I am happy with the assembly. He checked with me design features before going ahead",
  },
  {
    id: 3,
    profilePic: profile1,
    firstName: "Mark",
    lastName: "Tomson",
    reviewStar: 4,
    reviewText:
      "Fantastic. Exceptionally efficient and effective with all the jobs done to a very high standard. He was also very friendly.",
  },
  {
    id: 4,
    profilePic: profile2,
    firstName: "Jason",
    lastName: "Smith",
    reviewStar: 2,
    reviewText:
      "Fantastic. Exceptionally efficient and effective with all the jobs done to a very high standard. He was also very friendly.",
  },
];

const area_list = [
  {
    cityId: 1,
    cityImg: area1,
    cityName: "Belfast",
  },
  {
    cityId: 2,
    cityImg: area2,
    cityName: "Birmingham",
  },
  {
    cityId: 3,
    cityImg: area3,
    cityName: "Bradford",
  },
  {
    cityId: 4,
    cityImg: area4,
    cityName: "Brighton",
  },
  {
    cityId: 5,
    cityImg: area5,
    cityName: "Bristol",
  },
  {
    cityId: 6,
    cityImg: area6,
    cityName: "Cardiff",
  },
  {
    cityId: 7,
    cityImg: area7,
    cityName: "Coventry",
  },
  {
    cityId: 8,
    cityImg: area8,
    cityName: "Derby",
  },
  {
    cityId: 9,
    cityImg: area9,
    cityName: "Edinburgh",
  },
  {
    cityId: 10,
    cityImg: area10,
    cityName: "Glasgow",
  },
  {
    cityId: 11,
    cityImg: area11,
    cityName: "Kingston upon Hull",
  },
  {
    cityId: 12,
    cityImg: area12,
    cityName: "Leicester",
  },
  {
    cityId: 13,
    cityImg: area13,
    cityName: "Liverpool",
  },
  {
    cityId: 14,
    cityImg: area14,
    cityName: "London",
  },
  {
    cityId: 15,
    cityImg: area15,
    cityName: "Manchester",
  },
  {
    cityId: 16,
    cityImg: area16,
    cityName: "Newcastle upon Tyne ",
  },
  {
    cityId: 17,
    cityImg: area17,
    cityName: "Newport",
  },
  {
    cityId: 18,
    cityImg: area18,
    cityName: "Northampton",
  },
  {
    cityId: 19,
    cityImg: area19,
    cityName: "Nottingham",
  },
  {
    cityId: 20,
    cityImg: area20,
    cityName: "Plymouth",
  },
  {
    cityId: 21,
    cityImg: area21,
    cityName: "Portsmouth",
  },
  {
    cityId: 22,
    cityImg: area22,
    cityName: "Reading",
  },
  {
    cityId: 23,
    cityImg: area23,
    cityName: "Sheffield",
  },
  {
    cityId: 24,
    cityImg: area24,
    cityName: "Slough",
  },
  {
    cityId: 25,
    cityImg: area25,
    cityName: "Southampton",
  },
  {
    cityId: 26,
    cityImg: area26,
    cityName: "Stoke-on-trent ",
  },
  {
    cityId: 27,
    cityImg: area27,
    cityName: "Warrington",
  },
  {
    cityId: 28,
    cityImg: area28,
    cityName: "Wolverhampton",
  },
];

const NewHome = () => {
  const navigate = useNavigate();
  const sliderRef = useRef(null);
  const serviceRef = useRef(null);
  const reviewRef = useRef(null);
  const token = localStorage.getItem("user_token");
  const [searchDropdown, setSearchDropdown] = useState(false);
  const [activeCategory, setActiveCategory] = useState(1);

  const showSearchDropdown = () => {
    setSearchDropdown(true);
  };

  const hideSearchDropdown = () => {
    setSearchDropdown(false);
  };

  var settings = {
    dots: false,
    speed: 500,
    infinite: true,
    centerPadding: "5px",
    slidesToShow: 9,
    slidesToScroll: 1,
    initialSlide: 0,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 1440,
        settings: {
          slidesToShow: 8,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 7,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 6,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 6,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 350,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
    ],
  };

  const handleActive = (serviceId) => {
    setActiveCategory(serviceId);
  };

  var service_settings = {
    dots: false,
    speed: 500,
    infinite: true,
    centerPadding: "20px",
    slidesToShow: 2,
    slidesToScroll: 1,
    initialSlide: 0,
    cssEase: "linear",
  };

  var popular_service_settings = {
    dots: false,
    speed: 500,
    infinite: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    initialSlide: 0,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 1536,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
    ],
  };

  const review_settings = {
    dots: false,
    speed: 1000,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplaySpeed: 2000,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 1440,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const area_settings = {
    dots: true,
    infinite: true,
    slidesToShow: 6,
    slidesToScroll: 1,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 2000,
    cssEase: "linear",
    rows: 2,
    responsive: [
      {
        breakpoint: 1440,
        settings: {
          slidesToShow: 6,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 6,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 2,
          infinite: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 2,
          initialSlide: 2,
          rows: 3,
        },
      },
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          rows: 4,
        },
      },
    ],
  };

  const handleBook = (e) => {
    navigate("/services");
  };

  return (
    <>
      {token ? <TopBar /> : <NewHeader />}
      <section className="2xl:mt-16 xl:mt-20 lg:mt-[68px] mt-[65px]">
        <div
          className="bg-cover bg-center inset-0 2xl:h-[600px] xl:h-[500px] h-[500px] w-full"
          style={{ backgroundImage: `url(${bg1})` }}
        >
          <div className="2xl:mx-32 xl:mx-20 lg:mx-14 md:mx-10 mx-4 h-full">
            <div className="flex justify-center items-center h-full text-center">
              <div className="w-full">
                <h1 className="2xl:text-[55px] xl:text-[50px] lg:text-[45px] md:text-[40px] text-[30px] text-white font-medium landing-title">
                  Same day home services
                </h1>
                <h5 className="text-white tracking-wider 2xl:text-xl xl:text-x lg:text-lg">
                  There is no waiting around with Taskbudy; it is all about
                  getting things done!
                </h5>
                <div className="mt-4 flex justify-center">
                  <div className="bg-[#FFFFFF66] 2xl:p-3 xl:p-3 lg:p-3 p-2 rounded-full 2xl:w-[700px] xl:w-[700px] lg:w-[600px] md:w-[500px] w-[300px]">
                    <form className="relative flex gap-2 bg-white items-center rounded-full px-2 py-1.5">
                      <div className="relative flex items-center gap-1 w-full">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="currentColor"
                          className="w-8 h-8 text-gray-500 ml-2 2xl:block xl:block lg:block md:block hidden"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M21 21l-4.35-4.35M16.5 10.5a6 6 0 11-12 0 6 6 0 0112 0z"
                          />
                        </svg>
                        <input
                          type="text"
                          name="email_address"
                          placeholder="What service do you need?"
                          className="w-full focus:outline-none ring-0 ml-1"
                          onFocus={showSearchDropdown}
                          onBlur={hideSearchDropdown}
                          required
                        />
                      </div>
                      <button
                        type="submit"
                        className="2xl:block xl:block lg:block md:block hidden bg-[#1976D2] text-white font-medium py-2 px-7 rounded-full tracking-wider"
                      >
                        Search
                      </button>
                      <button
                        type="submit"
                        className="2xl:hidden xl:hidden lg:hidden md:hidden block bg-[#1976D2] text-white font-medium p-2 rounded-full tracking-wider"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="currentColor"
                          className="w-5 h-5 text-white"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M21 21l-4.35-4.35M16.5 10.5a6 6 0 11-12 0 6 6 0 0112 0z"
                          />
                        </svg>
                      </button>
                      <Transition
                        as={Fragment}
                        show={searchDropdown}
                        enter="transition-all ease-linear duration-150"
                        enterFrom="mt-5 invisible opacity-0 translate-y-1"
                        enterTo="mt-[3px] visible opacity-100 translate-y-0"
                        leave="transition-all ease-linear duration-150"
                        leaveFrom="mt-[3px] visible opacity-100 translate-y-0"
                        leaveTo="mt-5 invisible opacity-0 translate-y-1"
                      >
                        <div className="absolute left-0 top-[50px] z-10 mt-[3px] w-full">
                          <div className="p-5 box rounded-xl h-[300px] overflow-auto">
                            {category_arr.map((item, index) => {
                              return (
                                <div
                                  className="mb-2 p-2 flex justify-between items-center cursor-pointer"
                                  key={index}
                                >
                                  <h5 className="font-medium text-start">
                                    {item.serviceName}
                                  </h5>
                                  <div>
                                    <MoveUpRight color="#1976d2" />
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      </Transition>
                    </form>
                  </div>
                </div>
                <div className="mt-12">
                  <div className="px-5">
                    <Slider {...settings}>
                      {category_arr.map((item, index) => {
                        return (
                          <div
                            className="flex justify-center items-center h-full"
                            key={index}
                          >
                            <div
                              className={`${
                                activeCategory === item.serviceId
                                  ? "bg-[#1976D2CC]"
                                  : "bg-[#1976D266]"
                              } cursor-pointer border border-2 border-[#D9D9D9] 2xl:p-4 xl:p-3 lg:p-3 md:p-2.5 p-2 rounded-lg  2xl:w-[150px] xl:w-[120px] lg:w-[120px] md:w-[100px] w-[90px]`}
                              onClick={(e) => handleActive(item.serviceId)}
                            >
                              <div className="flex justify-center items-center">
                                <img
                                  src={item.serviceIcon}
                                  alt="service"
                                  className="2xl:h-14 xl:h-14 lg:h-14 h-12"
                                />
                              </div>
                              <h5 className="text-white text-center font-normal 2xl:text-base xl:text-sm lg:text-sm text-xs 2xl:mt-5 mt-3">
                                {item.serviceName}
                              </h5>
                            </div>
                          </div>
                        );
                      })}
                    </Slider>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <section className="">
        <div className="bg-[#F2F2F2]">
          <div className="2xl:mx-32 xl:mx-20 lg:mx-14 md:mx-10 mx-4">
            <div className="pt-10 pb-6">
              <h5 className="text-[#20448C] font-semibold text-3xl text-center">
                Select your service
              </h5>
              <div className="grid grid-cols-12 my-8 border border-[#CBCBCB]">
                {services_arr.map((item, index) => (
                  <div
                    className={`2xl:col-span-2 xl:col-span-2 lg:col-span-3 col-span-4
                     border-t border-r border-[#CBCBCB]`}
                    key={index}
                  >
                    <div className="flex items-center gap-2 2xl:px-6 xl:px-3 lg:px-3 md:px-3 px-2 2xl:py-4 xl:py-4 lg:py-4 py-3">
                      {item.serviceIcon ? (
                        <img
                          alt=""
                          src={item.serviceIcon}
                          className="2xl:h-10 2xl:w-auto xl:h-10 xl:w-auto lg:h-10 lg:w-auto md:h-10 md:w-auto h-6 w-8"
                        />
                      ) : (
                        <p className="2xl:h-10 xl:h-8 h-8"></p>
                      )}
                      <h5 className="text-[#3D3C3C] font-medium 2xl:text-sm xl:text-sm lg:text-base md:text-sm text-[10px] break-word">
                        {item.serviceName}
                      </h5>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </section> */}
      {/* <section>
        <div className="bg-[#F2F2F2]">
          <div className="2xl:mx-32 xl:mx-20 lg:mx-14 md:mx-10 mx-4">
            <div className="pt-10 pb-6">
              <h5 className="text-[#20448C] font-semibold text-3xl text-center">
                Select your service
              </h5>
              <div className="grid grid-cols-12 my-8 custom-grid">
                {services_arr.map((item, index) => (
                  <div
                    className="grid-item 2xl:col-span-2 xl:col-span-2 lg:col-span-3 col-span-4"
                    key={index}
                  >
                    <div className="flex items-center gap-2 2xl:px-6 xl:px-3 lg:px-3 md:px-3 px-2 2xl:py-4 xl:py-4 lg:py-4 py-3">
                      {item.serviceIcon ? (
                        <img
                          alt=""
                          src={item.serviceIcon}
                          className="2xl:h-10 2xl:w-auto xl:h-10 xl:w-auto lg:h-10 lg:w-auto md:h-10 md:w-auto h-6 w-8"
                        />
                      ) : (
                        <p className="2xl:h-10 xl:h-8 h-8"></p>
                      )}
                      <h5 className="text-[#3D3C3C] font-medium 2xl:text-sm xl:text-sm lg:text-base md:text-sm text-[10px] break-word">
                        {item.serviceName}
                      </h5>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </section> */}
      <section className="">
        <div className="bg-[#F2F2F2]">
          <div className="2xl:mx-32 xl:mx-20 lg:mx-14 md:mx-10 mx-4">
            <div className="pt-10 pb-6">
              <h5 className="text-[#20448C] font-semibold text-3xl text-center">
                Select your service
              </h5>
              <div className="custom-grid my-8">
                {services_arr.map((item, index) => (
                  <div className="grid-item" key={index}>
                    <div className="flex items-center gap-2 2xl:px-6 xl:px-3 lg:px-3 md:px-3 px-2 2xl:py-4 xl:py-4 lg:py-4 py-3">
                      {item.serviceIcon ? (
                        <img
                          alt=""
                          src={item.serviceIcon}
                          className="2xl:h-10 2xl:w-auto xl:h-10 xl:w-auto lg:h-10 lg:w-auto md:h-10 md:w-auto h-6 w-8"
                        />
                      ) : (
                        <p className="2xl:h-10 xl:h-8 h-8"></p>
                      )}
                      <h5 className="text-[#3D3C3C] font-medium 2xl:text-sm xl:text-sm lg:text-base md:text-sm text-[10px] break-word">
                        {item.serviceName}
                      </h5>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="2xl:py-10 xl:py-10 py-8">
        <div className="2xl:mx-32 xl:mx-20 lg:mx-14 md:mx-10 mx-4">
          {/* <div className="grid grid-cols-12 2xl:gap-8 xl:gap-6 gap-2">
            <div className="2xl:col-span-4 xl:col-span-4 lg:col-span-4 md:col-span-4 col-span-12">
              <div className="flex items-center text-[#20448C] px-4 py-3">
                <img
                  src={checkIcon}
                  alt="check-icon"
                  className="2xl:h-[70px] xl:h-16 h-14"
                ></img>
                <div>
                  <h6 className="text-lg font-semibold">Local</h6>
                  <h4 className="font-bold 2xl:text-2xl xl:text-2xl lg:text-xl text-xl">
                    Independent Fixers
                  </h4>
                </div>
              </div>
            </div>
            <div className="2xl:col-span-4 xl:col-span-4 lg:col-span-4 md:col-span-4 col-span-12">
              <div className="flex items-center justify-center text-[#20448C] px-4 py-3">
                <img
                  src={checkIcon}
                  alt="check-icon"
                  className="2xl:h-[70px] xl:h-16 h-14"
                ></img>
                <div>
                  <h6 className="text-lg font-semibold">Fully</h6>
                  <h4 className="font-bold 2xl:text-2xl xl:text-2xl lg:text-xl text-xl">
                    Insured & Qualified
                  </h4>
                </div>
              </div>
            </div>
            <div className="2xl:col-span-4 xl:col-span-4 lg:col-span-4 md:col-span-4 col-span-12">
              <div className="flex items-center justify-end text-[#20448C] px-4 py-3">
                <img
                  src={checkIcon}
                  alt="check-icon"
                  className="2xl:h-[70px] xl:h-16 h-14"
                ></img>
                <div>
                  <h6 className="text-lg font-semibold">Quality</h6>
                  <h4 className="font-bold 2xl:text-2xl xl:text-2xl lg:text-xl text-xl">
                    Work Guaranteed
                  </h4>
                </div>
              </div>
            </div>
          </div> */}
          <div className="flex 2xl:flex-nowrap xl:flex-nowrap lg:flex-nowrap md:flex-nowrap flex-wrap justify-between items-center">
            <div className="flex items-center text-[#20448C] px-4 py-3">
              <img
                src={checkIcon}
                alt="check-icon"
                className="2xl:h-[70px] xl:h-16 h-14"
              ></img>
              <div>
                <h6 className="2xl:text-xl xl:text-xl text-lg font-semibold">
                  Local
                </h6>
                <h4 className="font-bold 2xl:text-3xl xl:text-2xl lg:text-xl text-xl">
                  Independent Fixers
                </h4>
              </div>
            </div>
            <div className="flex items-center justify-center text-[#20448C] px-4 py-3">
              <img
                src={checkIcon}
                alt="check-icon"
                className="2xl:h-[70px] xl:h-16 h-14"
              ></img>
              <div>
                <h6 className="2xl:text-xl xl:text-xl text-lg font-semibold">
                  Fully
                </h6>
                <h4 className="font-bold 2xl:text-3xl xl:text-2xl lg:text-xl text-xl">
                  Insured & Qualified
                </h4>
              </div>
            </div>
            <div className="flex items-center justify-end text-[#20448C] px-4 py-3">
              <img
                src={checkIcon}
                alt="check-icon"
                className="2xl:h-[70px] xl:h-16 h-14"
              ></img>
              <div>
                <h6 className="2xl:text-xl xl:text-xl text-lg font-semibold">
                  Quality
                </h6>
                <h4 className="font-bold 2xl:text-3xl xl:text-2xl lg:text-xl text-xl">
                  Work Guaranteed
                </h4>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="2xl:py-10 xl:py-10 py-8">
        <div className="2xl:mx-32 xl:mx-20 lg:mx-14 md:mx-10 mx-4">
          <h2 className="text-[#20448C] 2xl:text-4xl xl:text-3xl text-2xl font-medium">
            Quick Guide on selecting a service
          </h2>
          <div className="grid grid-cols-12 2xl:gap-6 xl:gap-6 lg:gap-6 gap-3 pt-5">
            <div className="2xl:col-span-3 xl:col-span-3 lg:col-span-3 col-span-6">
              <div
                className="p-4 border border-[#BCBCBC] rounded h-full"
                style={{ boxShadow: "0px 4px 3px #00000030" }}
              >
                <div className="">
                  <img
                    src={q1}
                    alt=""
                    className="2xl:h-12 xl:h-12 lg:h-12 md:h-10 h-8"
                  ></img>
                </div>
                <h5 className="mt-3 2xl:text-base xl:text-base lg:text-base md:text-base text-sm">
                  Select the job category you require
                </h5>
              </div>
            </div>
            <div className="2xl:col-span-3 xl:col-span-3 lg:col-span-3 col-span-6">
              <div
                className="p-4 border border-[#BCBCBC] rounded h-full"
                style={{ boxShadow: "0px 4px 3px #00000030" }}
              >
                <div className="">
                  <img
                    src={q2}
                    alt=""
                    className="2xl:h-12 xl:h-12 lg:h-12 md:h-10 h-8"
                  ></img>
                </div>
                <h5 className="mt-3 2xl:text-base xl:text-base lg:text-base md:text-base text-sm">
                  Then select a services from the category
                </h5>
              </div>
            </div>
            <div className="2xl:col-span-3 xl:col-span-3 lg:col-span-3 col-span-6">
              <div
                className="p-4 border border-[#BCBCBC] rounded h-full"
                style={{ boxShadow: "0px 4px 3px #00000030" }}
              >
                <div className="">
                  <img
                    src={q3}
                    alt=""
                    className="2xl:h-12 xl:h-12 lg:h-12 md:h-10 h-8"
                  ></img>
                </div>
                <h5 className="mt-3 2xl:text-base xl:text-base lg:text-base md:text-base text-sm">
                  Describe the job and upload some pictures
                </h5>
              </div>
            </div>
            <div className="2xl:col-span-3 xl:col-span-3 lg:col-span-3 col-span-6">
              <div
                className="p-4 border border-[#BCBCBC] rounded h-full"
                style={{ boxShadow: "0px 4px 3px #00000030" }}
              >
                <div className="">
                  <img
                    src={q4}
                    alt=""
                    className="2xl:h-12 xl:h-12 lg:h-12 md:h-10 h-8"
                  ></img>
                </div>
                <h5 className="mt-3 2xl:text-base xl:text-base lg:text-base md:text-base text-sm">
                  Request for immediate service or select a date
                </h5>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="2xl:py-8 ">
        <div className="2xl:mx-32 xl:mx-20 lg:mx-14 md:mx-10 mx-4">
          <div className="flex items-center justify-between">
            <h2 className="text-[#20448C] 2xl:text-4xl xl:text-3xl text-2xl font-medium">
              Our Same days Services
            </h2>
            <div className="flex items-center gap-4 2xl:hidden xl:hidden lg:hidden md:hidden block">
              <button
                className="bg-[#20448C] text-white p-1 rounded-full shadow-lg cursor-pointer"
                onClick={() => sliderRef.current.slickPrev()}
              >
                <GrFormPrevious fontSize={25} />
              </button>
              <button
                className="bg-[#20448C] text-white p-1 rounded-full shadow-lg cursor-pointer"
                onClick={() => sliderRef.current.slickNext()}
              >
                <GrFormNext fontSize={25} />
              </button>
            </div>
          </div>
          <div className="my-5">
            <div className="2xl:block xl:block lg:block md:block hidden">
              <div className="grid grid-cols-12 2xl:gap-5 xl:gap-5 lg:gap-4 gap-3">
                {serviceimg_arr.map((item, index) => {
                  return (
                    <div
                      className="2xl:col-span-4 xl:col-span-4 lg:col-span-4 md:col-span-4 col-span-6"
                      key={index}
                    >
                      <div className="relative">
                        <img
                          src={item.serviceImg}
                          alt=""
                          className="object-cover object-center 2xl:h-[350px] xl:h-[320px] lg:h-[300px] md:h-[250px] h-[260px] w-full rounded-lg"
                        />
                        <Disclosure className="">
                          {({ open }) => (
                            <div
                              className={`absolute bottom-0 left-0 w-full 2xl:p-5 xl:p-5 lg:p-4 p-2 rounded-b-md transition-all duration-300 ${
                                open
                                  ? "bg-[#1976D2CC] h-full rounded-t-md flex items-center justify-center"
                                  : "bg-[#0E0E0ECC]"
                              }`}
                            >
                              <div className="w-full">
                                <Disclosure.Button className="group flex items-center justify-center w-full gap-2 text-white">
                                  <h4 className="font-medium 2xl:text-lg xl:text-lg lg:text-lg text-base md:truncate">
                                    {item.serviceName}
                                  </h4>
                                  <ChevronUpIcon
                                    className={`min-w-8 transform transition-transform duration-300 ${
                                      open ? "rotate-180" : "rotate-0"
                                    }`}
                                  />
                                </Disclosure.Button>
                                <Disclosure.Panel className="2xl:mt-4 xl:mt-4 lg:mt-4 mt-3 flex justify-center items-center">
                                  <h5 className="text-white text-center font-extralight text-sm 2xl:tracking-wider xl:tracking-wider lg:tracking-wider 2xl:line-clamp-none xl:line-clamp-none lg:line-clamp-none line-clamp-6">
                                    {item.description}
                                  </h5>
                                </Disclosure.Panel>
                              </div>
                            </div>
                          )}
                        </Disclosure>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="2xl:hidden xl:hidden lg:hidden md:hidden block">
              <div className="today-service-slider">
                <Slider
                  ref={sliderRef}
                  {...{
                    ...service_settings,
                    arrows: false,
                  }}
                >
                  {serviceimg_arr.map((item, index) => {
                    return (
                      <div className="px-1" key={index}>
                        <div className="relative">
                          <img
                            src={item.serviceImg}
                            alt=""
                            className="object-cover object-center h-[250px] w-full rounded-lg"
                          />
                          <Disclosure className="">
                            {({ open }) => (
                              <div
                                className={`absolute bottom-0 left-0 w-full px-2 py-2.5 rounded-b-md transition-all duration-300 ${
                                  open
                                    ? "bg-[#1976D2CC] h-full rounded-t-md flex items-center justify-center"
                                    : "bg-[#0E0E0ECC]"
                                }`}
                              >
                                <div className="w-full">
                                  <Disclosure.Button className="group flex items-center justify-center w-full gap-2 text-white">
                                    <h4 className="font-normal text-xs whitespace-nowrap truncate">
                                      {item.serviceName}
                                    </h4>
                                    <ChevronUpIcon
                                      className={`min-w-6 w-6 transform transition-transform duration-300 ${
                                        open ? "rotate-180" : "rotate-0"
                                      }`}
                                    />
                                  </Disclosure.Button>
                                  <Disclosure.Panel className="mt-3 flex justify-center items-center">
                                    <h5 className="text-white text-center font-extralight text-xs line-clamp-6">
                                      {item.description}
                                    </h5>
                                  </Disclosure.Panel>
                                </div>
                              </div>
                            )}
                          </Disclosure>
                        </div>
                      </div>
                    );
                  })}
                </Slider>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="py-6">
        <div className="2xl:mx-32 xl:mx-20 lg:mx-14 md:mx-10 mx-4">
          <div className="flex items-center justify-between">
            <h2 className="text-[#20448C] 2xl:text-4xl xl:text-3xl text-2xl font-medium">
              Popular Services
            </h2>
            <div className="flex items-center gap-4">
              <button
                className="bg-[#20448C] text-white p-1 rounded-full shadow-lg cursor-pointer"
                onClick={() => serviceRef.current.slickPrev()}
              >
                <GrFormPrevious fontSize={25} />
              </button>
              <button
                className="bg-[#20448C] text-white p-1 rounded-full shadow-lg cursor-pointer"
                onClick={() => serviceRef.current.slickNext()}
              >
                <GrFormNext fontSize={25} />
              </button>
            </div>
          </div>
          <div className="my-5">
            <Slider
              ref={serviceRef}
              {...{
                ...popular_service_settings,
                arrows: false,
              }}
              className="popular-service-settings"
            >
              {popular_service_arr.map((item, index) => {
                return (
                  <div
                    className="pr-3 2xl:col-span-3 xl:col-span-3 lg:col-span-3 md:col-span-3 col-span-6"
                    key={index}
                  >
                    <div className="">
                      <div className="bg-[#008CDB] rounded-t-md 2xl:p-4 xl:p-4 lg:p-4 p-3">
                        <h6 className="font-normal 2xl:text-lg xl:text-lg md:text-base text-sm text-[#E5E2FF] whitespace-nowrap">
                          {item.serviceName}
                        </h6>
                        <h4 className="font-semibold 2xl:text-xl xl:text-xl lg:text-xl text-lg text-white mt-1">
                          {item.price}
                        </h4>
                      </div>
                      <div className="relative">
                        <img
                          alt=""
                          src={item.serviceImg}
                          className="2xl:h-[360px] xl:h-[300px] lg:h-[300px] md:h-[250px] h-[200px] w-full object-cover object-center rounded-b-md"
                        ></img>
                        <button
                          style={{ boxShadow: "0px 4px 6px #00000040" }}
                          className="absolute bottom-8 left-1/2 transform -translate-x-1/2 bg-[#008CDB] text-white font-medium 2xl:px-8 xl:px-6 lg:px-5 md:px-4 px-3 2xl:py-3 xl:py-3 lg:py-3 py-2 rounded-full 2xl:text-base xl:text-base lg:text-base md:text-base text-sm whitespace-nowrap"
                          onClick={(e) => handleBook()}
                        >
                          Book Now
                        </button>
                      </div>
                    </div>
                  </div>
                );
              })}
            </Slider>
          </div>
        </div>
      </section>
      <section className="mt-6 bg-[#008CDB] text-white">
        <div className="2xl:mx-32 xl:mx-20 lg:mx-14 md:mx-10">
          <div className="grid grid-cols-12 2xl:gap-x-6 xl:gap-x-6 gap-x-3 h-full">
            <div className="col-span-12 2xl:col-span-6 xl:col-span-6 lg:col-span-6 md:col-span-6 my-6 h-auto 2xl:mx-0 xl:mx-0 lg:mx-0 md:mx-0 mx-4">
              <div className="flex items-center justify-center h-full">
                <div>
                  <h2 className="2xl:text-4xl xl:text-3xl text-2xl font-medium">
                    Why Choose Us
                  </h2>
                  <div className="grid grid-cols-12 2xl:gap-x-6 xl:gap-4 gap-x-3 2xl:gap-y-8 xl:gap-y-8 lg:gap-y-8 gap-y-5 mt-8">
                    <div className="col-span-12 2xl:col-span-6 xl:col-span-6 lg:col-span-12 md:col-span-12">
                      <div className="flex gap-2">
                        <img
                          src={img1}
                          alt=""
                          className="2xl:h-24 xl:h-20 lg:h-20 h-16"
                        ></img>
                        <div>
                          <h5 className="font-medium 2xl:text-lg xl:text-lg lg:text-lg">
                            ID-checked Fixers
                          </h5>
                          <h6 className="mt-1 text-sm font-normal">
                            Fixers are always identity-checked before joining
                            the platform
                          </h6>
                        </div>
                      </div>
                    </div>
                    <div className="col-span-12 2xl:col-span-6 xl:col-span-6 lg:col-span-12 md:col-span-12">
                      <div className="flex gap-2">
                        <img
                          src={img2}
                          alt=""
                          className="2xl:h-24 xl:h-20 lg:h-20 h-16"
                        ></img>
                        <div>
                          <h5 className="font-medium 2xl:text-lg xl:text-lg lg:text-lg">
                            Dedicated Support
                          </h5>
                          <h6 className="mt-1 text-sm font-normal">
                            Friendly service when you need us every day of the
                            week.
                          </h6>
                        </div>
                      </div>
                    </div>
                    <div className="col-span-12 2xl:col-span-6 xl:col-span-6 lg:col-span-12 md:col-span-12">
                      <div className="flex gap-2">
                        <img
                          src={img3}
                          alt=""
                          className="2xl:h-24 xl:h-20 lg:h-20 h-16"
                        ></img>
                        <div>
                          <h5 className="font-medium 2xl:text-lg xl:text-lg lg:text-lg">
                            Same-Day Service
                          </h5>
                          <h6 className="mt-1 text-sm font-normal">
                            Fixers are always identitychecked before joining the
                            platform.
                          </h6>
                        </div>
                      </div>
                    </div>
                    <div className="col-span-12 2xl:col-span-6 xl:col-span-6 lg:col-span-12 md:col-span-12">
                      <div className="flex gap-2">
                        <img
                          src={img4}
                          alt=""
                          className="2xl:h-24 xl:h-20 lg:h-20 h-16"
                        ></img>
                        <div>
                          <h5 className="font-medium 2xl:text-lg xl:text-lg lg:text-lg">
                            Health & Safety Assured
                          </h5>
                          <h6 className="mt-1 text-sm font-normal">
                            Fixers are always identitychecked before joining the
                            platform.
                          </h6>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-span-12 2xl:col-span-6 xl:col-span-6 lg:col-span-6 md:col-span-6">
              <img
                className="w-full 2xl:h-[550px] xl:h-[550px] lg:h-[550px] md:h-[500px] h-[400px] object-cover object-center"
                src={whyChoose}
                alt=""
              ></img>
            </div>
          </div>
        </div>
      </section>
      <section className="2xl:py-20 xl:py-16 lg:py-14 md:py-14 py-10 bg-[#20448C] mb-8">
        <div className="2xl:mx-32 xl:mx-20 lg:mx-14 md:mx-10 mx-4">
          {/* <div className="grid grid-cols-12 gap-y-8 gap-x-4 items-center justify-center">
            {statsData.map((stat, index) => (
              <div
                className="col-span-12 2xl:col-span-4 xl:col-span-4 lg:col-span-4 md:col-span-4 2xl:mx-0 xl:mx-0 lg:mx-0 md:mx-0 mx-[20%]"
                key={index}
              >
                <div className="flex items-center gap-x-6">
                  <img
                    src={stat.imgSrc}
                    alt={stat.label}
                    className="2xl:h-20 2xl:w-20 xl:h-20 xl:w-auto lg:h-16 lg:w-16 md:h-auto h-11 w-11"
                  />
                  <div className="text-white 2xl:text-center xl:text-center lg:text-center md:text-center text-start">
                    <h4 className="2xl:font-bold xl:font-bold lg:font-bold md:font-semibold font-medium 2xl:text-2xl xl:text-2xl lg:text-2xl md:text-xl text-lg tracking-wider">
                      {stat.value}
                    </h4>
                    <div className="flex items-center 2xl:justify-center xl:justify-center lg:justify-center md:justify-center mt-1">
                      <p className="border-b border-white 2xl:w-14 xl:w-12 lg:w-12 md:w-12 w-12"></p>
                    </div>
                    <h6 className="2xl:font-semibold xl:font-semibold lg:font-semibold md:font-medium sm:font-medium font-light 2xl:text-lg xl:text-lg lg:text-base text-sm mt-2">
                      {stat.label}
                    </h6>
                  </div>
                </div>
              </div>
            ))}
          </div> */}
          <div className="flex 2xl:flex-nowrap xl:flex-nowrap lg:flex-nowrap md:flex-nowrap flex-wrap items-center justify-between gap-y-8 gap-x-4">
            {statsData.map((stat, index) => (
              <div
                className="2xl:mx-0 xl:mx-0 lg:mx-0 md:mx-0 mx-[20%]"
                key={index}
              >
                <div className="flex items-center gap-x-6">
                  <img
                    src={stat.imgSrc}
                    alt={stat.label}
                    className="2xl:h-20 2xl:w-20 xl:h-20 xl:w-auto lg:h-18 lg:w-18 w-16"
                  />
                  <div className="text-white 2xl:text-center xl:text-center lg:text-center md:text-center text-start">
                    <h4 className="2xl:font-bold xl:font-bold lg:font-bold md:font-semibold font-medium 2xl:text-[40px] xl:text-[36px] lg:text-4xl md:text-2xl text-lg tracking-wider">
                      {stat.value}
                    </h4>
                    <div className="flex items-center 2xl:justify-center xl:justify-center lg:justify-center md:justify-center 2xl:my-4 xl:my-4 lg:my-3 my-2">
                      <p className="border-b border-white 2xl:w-14 xl:w-12 lg:w-12 md:w-12 w-12"></p>
                    </div>
                    <h6 className="2xl:font-semibold xl:font-semibold lg:font-semibold md:font-medium sm:font-medium font-light 2xl:text-[28px] xl:text-[24px] lg:text-[20px] md:text-base text-sm mt-2">
                      {stat.label}
                    </h6>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
      <section className="2xl:py-14 xl:py-12 lg:py-12 md:py-10 py-11">
        <div className="2xl:mx-32 xl:mx-20 lg:mx-14 md:mx-10 mx-4">
          <h2 className="text-[#20448C] 2xl:text-3xl xl:text-3xl text-2xl font-medium text-center">
            See what our clients say about our fixers
          </h2>
          <div className="mt-8 relative">
            <button
              className="absolute top-1/2 -left-[8px] transform -translate-y-1/2 bg-[#20448C] text-white p-1 rounded-full shadow-lg cursor-pointer"
              onClick={() => reviewRef.current.slickPrev()}
            >
              <GrFormPrevious fontSize={20} />
            </button>
            <button
              className="absolute top-1/2 -right-[8px] transform -translate-y-1/2 bg-[#20448C] text-white p-1 rounded-full shadow-lg cursor-pointer"
              onClick={() => reviewRef.current.slickNext()}
            >
              <GrFormNext fontSize={20} />
            </button>
            <div className="px-5">
              <Slider
                ref={reviewRef}
                {...{
                  ...review_settings,
                  arrows: false,
                }}
                className="h-full review-slide"
              >
                {review_arr.map((item, index) => {
                  return (
                    <div className="h-full px-3" key={index}>
                      <div className="p-4 border border-[#909090] bg-white rounded-lg shadow-[0px_4px_6px_#00000040] h-full">
                        <div className="flex items-center gap-2">
                          <img
                            alt=""
                            src={item.profilePic}
                            className="rounded-full h-14"
                          ></img>
                          <div>
                            <h5 className="text-[#2E2E2E] font-semibold">
                              {`${item.firstName} ${item.lastName}`}
                            </h5>
                            <div className="flex items-center margin-right-left">
                              {[1, 2, 3, 4, 5].map((star) => (
                                <span
                                  key={star}
                                  className="cursor-pointer"
                                  style={{
                                    cursor: "pointer",
                                    color:
                                      4 >= item.reviewStar
                                        ? "#FFC107"
                                        : "#D7D7D7",
                                    fontSize: "25px",
                                    padding: "0px",
                                  }}
                                >
                                  {" "}
                                  ★{" "}
                                </span>
                              ))}
                            </div>
                          </div>
                        </div>
                        <div className="mt-2">
                          <h5 className="text-[#6C6C6C] 2xl:text-base text-sm line-clamp-2">
                            {item.reviewText}
                          </h5>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </Slider>
            </div>
          </div>
        </div>
      </section>

      <section className="2xl:pt-12 xl:pt-12 lg:pt-10 md:pt-10 pt-11">
        <div
          className="bg-cover bg-center inset-0  h-full  w-full"
          style={{ backgroundImage: `url(${areaBg})` }}
        >
          <div className="2xl:mx-32 xl:mx-20 lg:mx-14 md:mx-10 mx-4 2xl:pt-12 2xl:pb-10 xl:pt-12 lg:pt-10 pt-6 2xl:pb-16 xl:pb-16 pb-14">
            <h2 className="text-[#20448C] 2xl:text-3xl xl:text-3xl text-2xl font-semibold text-center">
              Areas We Cover
            </h2>
            <Slider
              {...{
                ...area_settings,
                arrows: false,
              }}
              className="h-full area-slider pt-8"
            >
              {area_list.map((item, index) => {
                return (
                  <div
                    className="flex items-center justify-center h-full"
                    key={index}
                  >
                    <div className="2xl:p-4 xl:p-4 lg:p-4 md:p-3.5 p-2.5 h-full">
                      <div className="flex items-center justify-center w-full">
                        <div className="2xl:border-8 xl:border-8 lg:border-[6px] md:border-[7px] border-[5px] border-[#008CDB] rounded-full 2xl:h-44 2xl:w-44 xl:h-40 xl:w-40  h-auto w-auto">
                          <img
                            src={item.cityImg}
                            alt="area"
                            className="rounded-full h-full w-full object-center object-cover"
                          ></img>
                        </div>
                      </div>
                      <div className="mt-2 text-center">
                        <h5 className="text-[#3B3B3B] font-semibold 2xl:text-base xl:text-base lg:text-base md:text-base text-sm 2xl:whitespace-nowrap truncate">
                          {item.cityName}
                        </h5>
                      </div>
                    </div>
                  </div>
                );
              })}
            </Slider>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default NewHome;
