import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { loginbg } from "../../assets/images/base64/loginbg";
// import logo from "../../assets/images/logo/logo.png";
import logo from "../../assets/images/home/logo-lg.png";
import { Button, Spinner } from "@material-tailwind/react";
import { useFormik } from "formik";
import * as yup from "yup";
import { sendOtpApi } from "../../services/ApiServices";
import toast from "react-hot-toast";

const ForgotPassword = () => {
  const navigate = useNavigate();
  const [loader, setLoader] = useState(false);

  const validationSchema = yup.object().shape({
    emailId: yup
      .string()
      .required("Please enter Your Email Id")
      .matches(
        /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
        "Invalid email address"
      ),
  });

  const formik = useFormik({
    initialValues: {
      emailId: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      setLoader(true);
      const params = {
        emailId: values.emailId,
      };

      sendOtpApi(params)
        .then((res) => {
          const message = res.data.message;
          navigate("/forgotpassverification", {
            state: {
              email_id: values.emailId,
            },
          });
          toast.success(message || "Otp sended Successfully!!!");
          setLoader(false);
        })
        .catch((err) => {
          console.log("err >>>", err);
          setLoader(false);
        });
    },
  });

  return (
    <div>
      <div className="grid grid-cols-12">
        <div className="col-span-12 2xl:col-span-6 xl:col-span-6 lg:col-span-6 w-full 2xl:block xl:block lg:block hidden">
          <img
            src={loginbg}
            className="object-cover w-full h-screen"
            style={{ objectPosition: "100% 70%" }}
            alt=""
          ></img>
        </div>
        <div className="col-span-12 2xl:col-span-6 xl:col-span-6 lg:col-span-6 flex justify-center items-center">
          <div className="h-screen justify-center items-center flex">
            <div className="2xl:my-10 xl:my-10 lg:my-8 md:my-6 mx-5 min-w-72 w-auto">
              <div className="2xl:py-10 xl:py-10 lg:py-8 md:py-8 py-6 mx-5 2xl:min-w-96 xl:min-w-80 lg:min-w-80 md:min-w-80 min-w-72  w-auto">
                <div className="flex justify-center 2xl:py-10 py-5 2xl:hidden xl:hidden lg:hidden block">
                  <img src={logo} className="h-[70px]" alt="" />
                </div>
                <div className="pt-4 mb-2">
                  <h1 className="2xl:text-3xl xl:text-2xl text-xl font-medium">
                    Forgot Password
                  </h1>
                </div>
                <div className="my-4">
                  <p className="text-sm font-normal text-[#7E7E7E]">
                    Enter the email address you used when you joined to
                    <br />
                    reset your password.
                  </p>
                </div>
                <div className="py-4">
                  <form onSubmit={formik.handleSubmit}>
                    <div className="">
                      <label
                        className="block text-sm font-medium leading-6 text-gray-900 mb-2"
                        htmlFor="regular-form-1"
                      >
                        Work Email Address
                      </label>
                      <input
                        id="regular-form-1"
                        name="emailId"
                        type="email"
                        placeholder="Enter Email"
                        className="focus:outline-none py-3 px-4 block min-w-full xl:min-w-[350px] w-full rounded-xl border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#37489B] sm:text-sm sm:leading-6"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.emailId}
                      />
                      {formik.touched.emailId && formik.errors.emailId ? (
                        <div className="text-danger my-1 text-sm">
                          {formik.errors.emailId}
                        </div>
                      ) : null}
                    </div>
                    <div className="my-6 text-center">
                      <Button
                        className="flex items-center justify-center w-full text-sm py-3 rounded-lg bg-primary capitalize font-normal"
                        type="submit"
                        disabled={loader}
                      >
                        <span>Forgot Password</span>{" "}
                        {loader ? <Spinner className="h-4 w-4 ml-2" /> : ""}
                      </Button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
