import React from "react";
import { Routes, Route } from "react-router-dom";
import { authProtectedRoutes, publicRoutes } from "./allRoutes";
import { PublicRoute } from "./PublicRoute";
import PrivateRoute from "./PrivateRoute";
import MainLayout from "../layouts/MainLayout/MainLayout";

const index = () => {
  return (
    <>
      <Routes>
        <Route>
          {publicRoutes.map((item, index) => (
            <Route
              path={item.path}
              key={index}
              element={<PublicRoute>{item.components}</PublicRoute>}
            ></Route>
          ))}
        </Route>
        <Route>
          {authProtectedRoutes.map((item, index) => (
            <Route
              path={item.path}
              key={index}
              element={
                <PrivateRoute>
                  <MainLayout>{item.components}</MainLayout>
                </PrivateRoute>
              }
            ></Route>
          ))}
        </Route>
      </Routes>
    </>
  );
};

export default index;
