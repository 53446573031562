export const handymanTables = [
  {
    region: "Greater London",
    rows: [
      {
        description: "Labour",
        times: [
          ["8am - 6pm", "After Hours"],
          ["8am - 6pm", "After Hours"],
        ],
        rates: "",
      },
      {
        description: "First Hour",
        times: [
          ["£55", "£70"],
          ["£60", "£75"],
        ],
        rates: "£85",
      },
      {
        description: "Subsequent half hours",
        times: [
          ["£32.50", "£40"],
          ["£35", "£42.50"],
        ],
        rates: "£47.50",
      },
    ],
  },
  {
    region: "Rest of the Uk",
    rows: [
      {
        description: "Labour",
        times: [
          ["8am - 6pm", "After Hours"],
          ["8am - 6pm", "After Hours"],
        ],
        rates: "",
      },
      {
        description: "First Hour",
        times: [
          ["£40", "£55"],
          ["£45", "£60"],
        ],
        rates: "£70",
      },
      {
        description: "Subsequent half hours",
        times: [
          ["£22.50", "£30"],
          ["£25", "£32.50"],
        ],
        rates: "£37.50",
      },
    ],
  },
];

export const electricianTables = [
  {
    region: "Greater London",
    rows: [
      {
        description: "Labour",
        times: [
          ["8am - 6pm", "After Hours"],
          ["8am - 6pm", "After Hours"],
        ],
        rates: "",
      },
      {
        description: "First Hour",
        times: [
          ["£80", "£95"],
          ["£85", "£100"],
        ],
        rates: "£110",
      },
      {
        description: "Subsequent half hours",
        times: [
          ["£45", "£52.50"],
          ["£47.50", "£55"],
        ],
        rates: "£60",
      },
    ],
  },
  {
    region: "Rest of the Uk",
    rows: [
      {
        description: "Labour",
        times: [
          ["8am - 6pm", "After Hours"],
          ["8am - 6pm", "After Hours"],
        ],
        rates: "",
      },
      {
        description: "First Hour",
        times: [
          ["£65", "£80"],
          ["£70", "£85"],
        ],
        rates: "£95",
      },
      {
        description: "Subsequent half hours",
        times: [
          ["£35", "£42.50"],
          ["£37.50", "£45"],
        ],
        rates: "£50",
      },
    ],
  },
];

export const plumberTables = [
  {
    region: "Greater London",
    rows: [
      {
        description: "Labour",
        times: [
          ["8am - 6pm", "After Hours"],
          ["8am - 6pm", "After Hours"],
        ],
        rates: "",
      },
      {
        description: "First Hour",
        times: [
          ["£70", "£85"],
          ["£75", "£90"],
        ],
        rates: "£100",
      },
      {
        description: "Subsequent half hours",
        times: [
          ["£40", "£47.50"],
          ["£42.50", "£50"],
        ],
        rates: "£55",
      },
    ],
  },
  {
    region: "Rest of the Uk",
    rows: [
      {
        description: "Labour",
        times: [
          ["8am - 6pm", "After Hours"],
          ["8am - 6pm", "After Hours"],
        ],
        rates: "",
      },
      {
        description: "First Hour",
        times: [
          ["£55", "£70"],
          ["£60", "£75"],
        ],
        rates: "£80",
      },
      {
        description: "Subsequent half hours",
        times: [
          ["£30", "£37.50"],
          ["£32.50", "£40"],
        ],
        rates: "£42.50",
      },
    ],
  },
];

export const painterTables = [
  {
    region: "Greater London",
    rows: [
      {
        description: "Labour",
        times: [
          ["8am - 6pm", "After Hours"],
          ["8am - 6pm", "After Hours"],
        ],
        rates: "",
      },
      {
        description: "First Hour",
        times: [
          ["£40", "£55"],
          ["£45", "£60"],
        ],
        rates: "£75",
      },
      {
        description: "Subsequent half hours",
        times: [
          ["£25", "£32.50"],
          ["£27.50", "£35"],
        ],
        rates: "£42.50",
      },
    ],
  },
  {
    region: "Rest of the Uk",
    rows: [
      {
        description: "Labour",
        times: [
          ["8am - 6pm", "After Hours"],
          ["8am - 6pm", "After Hours"],
        ],
        rates: "",
      },
      {
        description: "First Hour",
        times: [
          ["£25", "£40"],
          ["£30", "£45"],
        ],
        rates: "£55",
      },
      {
        description: "Subsequent half hours",
        times: [
          ["£15", "£22.50"],
          ["£17.50", "£25"],
        ],
        rates: "£30",
      },
    ],
  },
];

export const cleanerTables = [
  {
    region: "Greater London",
    rows: [
      {
        description: "Labour",
        times: [
          ["8am - 6pm", "After Hours"],
          ["8am - 6pm", "After Hours"],
        ],
        rates: "",
      },
      {
        description: "First Hour",
        times: [
          ["£40", "£55"],
          ["£45", "£60"],
        ],
        rates: "£75",
      },
      {
        description: "Subsequent half hours",
        times: [
          ["£25", "£32.50"],
          ["£27.50", "£35"],
        ],
        rates: "£42.50",
      },
    ],
  },
  {
    region: "Rest of the Uk",
    rows: [
      {
        description: "Labour",
        times: [
          ["8am - 6pm", "After Hours"],
          ["8am - 6pm", "After Hours"],
        ],
        rates: "",
      },
      {
        description: "First Hour",
        times: [
          ["£25", "£40"],
          ["£30", "£45"],
        ],
        rates: "£55",
      },
      {
        description: "Subsequent half hours",
        times: [
          ["£15", "£22.50"],
          ["£17.50", "£25"],
        ],
        rates: "£30",
      },
    ],
  },
];

export const wasteRemovalTables = [
  {
    region: "Greater London",
    rows: [
      {
        description: "Labour",
        times: [
          ["8am - 6pm", "After Hours"],
          ["8am - 6pm", "After Hours"],
        ],
        rates: "",
      },
      {
        description: "First Hour",
        times: [
          ["£60", "£75"],
          ["£65", "£80"],
        ],
        rates: "£95",
      },
      {
        description: "Subsequent half hours",
        times: [
          ["£35", "£42.50"],
          ["£37.50", "£45"],
        ],
        rates: "£52.50",
      },
    ],
  },
  {
    region: "Rest of the Uk",
    rows: [
      {
        description: "Labour",
        times: [
          ["8am - 6pm", "After Hours"],
          ["8am - 6pm", "After Hours"],
        ],
        rates: "",
      },
      {
        description: "First Hour",
        times: [
          ["£45", "£60"],
          ["£50", "£65"],
        ],
        rates: "£75",
      },
      {
        description: "Subsequent half hours",
        times: [
          ["£25", "£32.50"],
          ["£27.50", "£35"],
        ],
        rates: "£40",
      },
    ],
  },
];

export const gardenerTables = [
  {
    region: "Greater London",
    rows: [
      {
        description: "Labour",
        times: [
          ["8am - 6pm", "After Hours"],
          ["8am - 6pm", "After Hours"],
        ],
        rates: "",
      },
      {
        description: "First Hour",
        times: [
          ["£40", "£55"],
          ["£45", "£60"],
        ],
        rates: "£75",
      },
      {
        description: "Subsequent half hours",
        times: [
          ["£25", "£32.50"],
          ["£27.50", "£35"],
        ],
        rates: "£42.50",
      },
    ],
  },
  {
    region: "Rest of the UK",
    rows: [
      {
        description: "Labour",
        times: [
          ["8am - 6pm", "After Hours"],
          ["8am - 6pm", "After Hours"],
        ],
        rates: "",
      },
      {
        description: "First Hour",
        times: [
          ["£25", "£40"],
          ["£30", "£45"],
        ],
        rates: "£55",
      },
      {
        description: "Subsequent half hours",
        times: [
          ["£15", "£22.50"],
          ["£17.50", "£25"],
        ],
        rates: "£30",
      },
    ],
  },
];

export const ManWithAVanTables = [
  {
    region: "Greater London",
    rows: [
      {
        description: "Labour",
        times: [
          ["8am - 6pm", "After Hours"],
          ["8am - 6pm", "After Hours"],
        ],
        rates: "",
      },
      {
        description: "First Hour",
        times: [
          ["£50", "£65"],
          ["£55", "£70"],
        ],
        rates: "£85",
      },
      {
        description: "Subsequent half hours",
        times: [
          ["£30", "£37.50"],
          ["£32.50", "£40"],
        ],
        rates: "£47.50",
      },
    ],
  },
  {
    region: "Rest of the UK",
    rows: [
      {
        description: "Labour",
        times: [
          ["8am - 6pm", "After Hours"],
          ["8am - 6pm", "After Hours"],
        ],
        rates: "",
      },
      {
        description: "First Hour",
        times: [
          ["£35", "£50"],
          ["£40", "£55"],
        ],
        rates: "£65",
      },
      {
        description: "Subsequent half hours",
        times: [
          ["£20", "£27.50"],
          ["£22.50", "£30"],
        ],
        rates: "£35",
      },
    ],
  },
];
