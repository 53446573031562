import React, { useState, useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  Button,
  Checkbox,
  Typography,
  Dialog,
  DialogBody,
  Spinner,
} from "@material-tailwind/react";
import { DayPicker } from "react-day-picker";
import { FaCircleChevronRight } from "react-icons/fa6";
import { FaCircleChevronLeft } from "react-icons/fa6";
import Lottie from "react-lottie";
import * as animationData from "../../assets/images/json/success.json";
import servicebg from "../../assets/images/pages/services/servicesbg.png";
import { IMAGE_URL } from "../../services/Api";
import he from "he";
import { useFormik } from "formik";
import * as yup from "yup";
import { MdClose } from "react-icons/md";
import noImage from "../../assets/images/no-image.png";
import fileImg from "../../assets/images/icons/file.png";
import moment from "moment/moment";
import {
  addBookingServiceApi,
  getPriceApi,
  listAllCityApi,
} from "../../services/ApiServices";
import toast from "react-hot-toast";
import Select from "react-select";
import makeAnimated from "react-select/animated";

// const time_arr = [
//   {
//     id: 1,
//     name: "09:00 AM",
//     value: "09:00:00",
//   },
//   {
//     id: 2,
//     name: "10:00 AM",
//     value: "10:00:00",
//   },
//   {
//     id: 3,
//     name: "11:00 AM",
//     value: "11:00:00",
//   },
//   {
//     id: 4,
//     name: "12:00 PM",
//     value: "12:00:00",
//   },
//   {
//     id: 5,
//     name: "01:00 PM",
//     value: "01:00:00",
//   },
//   {
//     id: 6,
//     name: "02:00 PM",
//     value: "02:00:00",
//   },
//   {
//     id: 7,
//     name: "03:00 PM",
//     value: "03:00:00",
//   },
//   {
//     id: 8,
//     name: "04:00 PM",
//     value: "04:00:00",
//   },
//   {
//     id: 9,
//     name: "05:00 PM",
//     value: "05:00:00",
//   },
//   {
//     id: 10,
//     name: "06:00 PM",
//     value: "06:00:00",
//   },
//   {
//     id: 11,
//     name: "07:00 PM",
//     value: "07:00:00",
//   },
//   {
//     id: 12,
//     name: "08:00 PM",
//     value: "08:00:00",
//   },
// ];

const generateTimeOptions = () => {
  const interval = 30;
  const timeOptions = [];
  let id = 1;

  for (let hour = 0; hour < 24; hour++) {
    for (let minute = 0; minute < 60; minute += interval) {
      const formattedHour = hour % 12 || 12;
      const formattedMinute =
        minute === 0 ? "00" : String(minute).padStart(2, "0");
      const ampm = hour < 12 ? "AM" : "PM";
      const timeString = `${formattedHour}:${formattedMinute} ${ampm}`;
      const timeValue = `${String(hour).padStart(
        2,
        "0"
      )}:${formattedMinute}:00`;

      timeOptions.push({
        id: id++,
        name: timeString,
        value: timeValue,
      });
    }
  }

  return timeOptions;
};

const BookService = () => {
  const location = useLocation();

  var service_details = "";
  try {
    const { data } = location.state;
    service_details = data;
  } catch (e) {
    //id not found
  }

  const { id } = useParams();
  const navigate = useNavigate();
  const animatedComponents = makeAnimated();
  const today = new Date();
  const disabledDays = { before: today };
  const [selectTime, setSelectTime] = useState("");
  const [open, setOpen] = React.useState(false);
  const [loader, setLoader] = useState(false);
  const [cityData, setCityData] = useState([]);
  const todays = new Date();
  const date = moment(todays).format("YYYY-MM-DD");
  const time = moment(todays).format("hh:mm:ss");
  const time_arr = generateTimeOptions();

  const handleOpen = () => setOpen(!open);

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setOpen(false);
    navigate("/mybooking");
  };

  const getAllCityInfo = () => {
    listAllCityApi()
      .then((res) => {
        const data = res.data.data;

        setCityData(data);
      })
      .catch((err) => {
        console.log("err >>>", err);
      });
  };

  useEffect(() => {
    getAllCityInfo();
  }, []);

  const city_options = cityData.map((item) => {
    return {
      label: item.city,
      value: item.cityId,
    };
  });

  const validate = (values) => {
    const errors = {};
    if (!values) {
      errors.selection = "Values are undefined";
      return errors;
    }
    if (!values.selection) {
      errors.selection = "You must select either Now or Later";
    } else if (values.selection === "later") {
      if (!values.bookingDate) {
        errors.bookingDate = "Booking date is required";
      }
      if (!values.bookingTime) {
        errors.bookingTime = "Booking time is required";
      }
    }
    return errors;
  };

  const validationSchema = yup.object().shape({
    requiredJob: yup.string().required("Please Describe Your required job"),
    requiredMaterials: yup
      .string()
      .required("Enter a required materials")
      .max(500, "Materials Must be 500 characters or less"),
    workingImage: yup
      .mixed()
      .required("Working image is required")
      .test("fileFormat", "Only Image files are allowed", (value) => {
        if (value) {
          const supportedFormats = ["jpg", "jpeg", "png"];
          return supportedFormats.includes(
            value.name.split(".").pop().toLowerCase()
          );
        }
        return true;
      }),
    description: yup.string().required("Please Enter a brief description"),
    city: yup.object().shape({
      label: yup.string().required("City is required"),
      value: yup.string().required("City is required"),
    }),
    address: yup.string().required("Please Enter an address"),
    selection: yup.string().required("You must select either Now or Later"),
    bookingDate: yup.date().nullable(),
    bookingTime: yup.string().nullable(),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      requiredJob: "",
      requiredMaterials: "",
      workingImage: "",
      description: "",
      city: null,
      address: "",
      selection: "",
      bookingDate: null,
      bookingTime: "",
    },
    validationSchema: validationSchema,
    validate,
    onSubmit: (values, { resetForm }) => {
      const subservice = service_details?.subServices
        .map((item) => item.serviceId)
        .toString();

      if (values.selection === "later") {
        if (validate()) {
          setLoader(true);
          const date = moment(values.bookingDate).format("YYYY-MM-DD");

          var formdata = new FormData();
          formdata.append("serviceId", id);
          formdata.append("subServiceId", subservice);
          formdata.append("requiredJob", values.requiredJob);
          formdata.append("requiredMaterials", values.requiredMaterials);
          formdata.append("description", values.description);
          formdata.append("address", values.description);
          formdata.append("workingImage", values.workingImage);
          formdata.append("bookingDate", date);
          formdata.append("bookingTime", values.bookingTime);
          formdata.append("cityId", values.city.value);
          formdata.append("price", values.price);

          addBookingServiceApi(formdata)
            .then((res) => {
              const message = res.data.message;
              handleOpen();
              toast.success(message || "Create your account Successfully!!!");
              setLoader(false);
              resetForm();
            })
            .catch((err) => {
              const message = err.response.data.message;
              setLoader(false);
              console.log("err >>>", err);
              toast.error(message || "Somthing went wrong!!");
            });
        }
      } else {
        setLoader(true);

        var formdata = new FormData();
        formdata.append("serviceId", id);
        formdata.append("subServiceId", subservice);
        formdata.append("requiredJob", values.requiredJob);
        formdata.append("requiredMaterials", values.requiredMaterials);
        formdata.append("description", values.description);
        formdata.append("address", values.description);
        formdata.append("workingImage", values.workingImage);
        formdata.append("bookingDate", date);
        formdata.append("bookingTime", time);
        formdata.append("cityId", values.city.value);
        formdata.append("price", values.price);

        addBookingServiceApi(formdata)
          .then((res) => {
            const message = res.data.message;
            handleOpen();

            toast.success(message || "Create your account Successfully!!!");
            setLoader(false);
            resetForm();
          })
          .catch((err) => {
            const message = err.response.data.message;
            setLoader(false);
            console.log("err >>>", err);
            toast.error(message || "Somthing went wrong!!");
          });
      }
    },
  });

  const getPriceDetails = () => {
    if (
      formik?.values?.selection === "later" &&
      formik?.values?.bookingDate &&
      formik?.values?.bookingTime &&
      formik?.values?.city?.value
    ) {
      const date = moment(formik?.values?.bookingDate).format("YYYY-MM-DD");
      const formattedTime = formik?.values?.bookingTime;

      getPriceApi(id, formik?.values?.city?.value, date, formattedTime)
        .then((res) => {
          const price = Number(res.data.price);
          formik.setFieldValue("price", price);
        })
        .catch((err) => {
          console.log("err >>>", err);
        });
    } else if (
      formik?.values?.selection === "now" &&
      formik?.values?.city?.value
    ) {
      getPriceApi(id, formik?.values?.city?.value, date, time)
        .then((res) => {
          const price = Number(res.data.price);
          formik.setFieldValue("price", price);
        })
        .catch((err) => {
          console.log("err >>>", err);
        });
    }
  };

  useEffect(() => {
    getPriceDetails();
  }, [
    formik?.values?.city,
    formik?.values?.bookingDate,
    formik?.values?.bookingTime,
    formik?.values?.selection,
  ]);

  const handleDayClick = (day) => {
    formik.setFieldValue("bookingDate", day);
  };

  const handleTimeClick = (time) => {
    setSelectTime(time);
    formik.setFieldValue("bookingTime", time);
  };

  const handleRemoveImage = () => {
    formik.setFieldValue("workingImage", "");
  };

  return (
    <>
      <div className="2xl:mt-20 xl:mt-20 lg:mt-22 mt-20 2xl:h-[940px] xl:h-[700px] lg:h-[600px] md:h-[500px] h-full">
        <div
          className="gradient-image-container"
          style={{
            backgroundImage: `linear-gradient(to bottom, rgba(0, 0, 0, 0.58), rgba(0, 0, 0, 0.64), rgba(0, 0, 0, 0)), url(${servicebg})`,
          }}
        >
          <div className="flex justify-center h-full">
            <div className="2xl:mx-32 xl:mx-20 lg:mx-14 md:mx-10 mx-4 h-full">
              <div className="py-5 2xl:px-8 xl:px-8 lg:px-5 px-5 text-white flex justify-center items-center h-full">
                <div className="my-4 max-w-[791px]">
                  <h1 className="text-center 2xl:text-[60px] xl:text-[40px] lg:text-[30px] text-3xl font-semibold tracking-wider py-4">
                    All Our Services
                  </h1>
                  <div className="py-4">
                    <h6 className="tracking-wide 2xl:text-lg text-base leading-7 font-medium">
                      Pick a service from our bag of tricks, tell us when you
                      fancy it, give a quick job rundown, and toss in any fun
                      pics you've got! Our crack team of Fixers is itching to
                      dive into action, whether it's today or whenever suits
                      your fancy. We're all about top-notch skills, a sprinkle
                      of professionalism, and a whole lot of dedication to wow
                      you with our service!
                    </h6>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="bg-primary 2xl:mb-3 xl:mb-3 lg:mb-3 md:mb-3">
        <div className="2xl:pt-10 xl:pt-10 lg:pt-8 md:pt-6 pt-5 2xl:pb-14 xl:pb-14 lg:pb-12 pb-14 flex justify-center">
          <h1 className="2xl:text-4xl xl:text-4xl lg:text-3xl text-2xl text-white font-medium uppercase ">
            Book Your <b className="text-info font-medium">Services</b>
          </h1>
        </div>
      </div>
      <div className="2xl:mx-32 xl:mx-20 lg:mx-14 md:mx-10 mx-4 bg-white 2xl:-mt-[40px] xl:-mt-[40px] lg:-mt-[40px] md:-mt-[50px] -mt-[40px] rounded-xl">
        <div className="2xl:p-6 xl:p-6 lg:p-6 md:p-6 p-4 w-full">
          <div className="grid grid-cols-12 2xl:gap-6 xl:gap-6 lg:gap-5 md:gap-4 gap-4">
            <div className="col-span-12 2xl:col-span-3 xl:col-span-3 lg:col-span-3 md:col-span-4">
              <div className="">
                <img
                  src={IMAGE_URL + service_details.serviceImage}
                  alt=""
                  className="2xl:w-[350px] 2xl:h-[350px] xl:w-[312px] xl:h-[312px] lg:w-[312px] lg:h-[312px] w-full h-full"
                  crossOrigin="anonymous"
                ></img>
              </div>
            </div>
            <div className="col-span-12 2xl:col-span-9 xl:col-span-9 lg:col-span-9 md:col-span-8">
              <div className="">
                <h3 className="text-primary 2xl:text-3xl xl:text-3xl lg:text-2xl md:text-2xl text-xl font-semibold">
                  {service_details.serviceName
                    ? he.decode(service_details.serviceName)
                    : ""}
                </h3>
                <div className="py-2">
                  <p className="text-[#363636] tracking-wide">
                    {service_details.serviceDescription
                      ? he.decode(he.decode(service_details.serviceDescription))
                      : "-"}
                  </p>
                </div>
                <div className="py-3 grid grid-cols-12 gap-3">
                  {service_details.subServices &&
                    service_details.subServices.map((category, i) => {
                      return (
                        <div
                          key={i}
                          className={`bg-primary border col-span-6 2xl:col-span-2 xl:col-span-3 lg:col-span-4 md:col-span-4 cursor-pointer p-3 flex justify-start items-center 2xl:h-[82px] xl:h-[82px] md:h-auto rounded-xl`}
                        >
                          <div className="">
                            <img
                              alt="category_image"
                              src={IMAGE_URL + category.serviceIcon}
                              className="2xl:max-w-[38px] 2xl:h-[40px] xl:max-w-10 xl:h-8 lg:max-w-10 md:max-w-8 max-w-8"
                              crossOrigin="anonymous"
                            ></img>
                          </div>
                          <h6 className="text-white 2xl:text-sm xl:text-sm lg:text-sm text-xs font-medium ml-2">
                            {category.serviceName
                              ? he.decode(he.decode(category.serviceName))
                              : ""}
                          </h6>
                        </div>
                      );
                    })}
                </div>
              </div>
            </div>
          </div>
          <div className="2xl:py-8 xl:py-8 lg:py-6 py-4 w-auto">
            <form onSubmit={formik.handleSubmit}>
              <div className="">
                <label
                  className="block font-medium leading-6 text-gray-900 mb-2"
                  htmlFor="regular-form-1"
                >
                  Something else
                </label>
                <input
                  id="regular-form-1"
                  name="requiredJob"
                  type="text"
                  placeholder="Describe your required job"
                  className="focus:outline-none py-3 px-4 block min-w-full xl:min-w-[350px] w-full rounded-xl border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-[#D9D9D9] placeholder:text-[#5A5A5A] placeholder:text-sm focus:ring-2 focus:ring-inset focus:ring-primary sm:text-sm sm:leading-6"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.requiredJob}
                ></input>
                {formik.touched.requiredJob && formik.errors.requiredJob ? (
                  <div className="text-danger my-1 text-sm">
                    {formik.errors.requiredJob}
                  </div>
                ) : null}
              </div>
              <div className="mt-3">
                <label
                  className="block font-medium leading-6 text-gray-900 mb-2"
                  htmlFor="regular-form-2"
                >
                  Do you require materials
                </label>
                <textarea
                  id="regular-form-2"
                  name="requiredMaterials"
                  type="text"
                  placeholder="Enter here if materials are required"
                  rows="6"
                  className="focus:outline-none py-3 px-4 block min-w-full xl:min-w-[350px] w-full rounded-xl border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-[#D9D9D9] placeholder:text-[#5A5A5A] placeholder:text-sm focus:ring-2 focus:ring-inset focus:ring-primary sm:text-sm sm:leading-6"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.requiredMaterials}
                ></textarea>
                {formik.touched.requiredMaterials &&
                formik.errors.requiredMaterials ? (
                  <div className="text-danger my-1 text-sm">
                    {formik.errors.requiredMaterials}
                  </div>
                ) : null}
              </div>
              <div className="grid grid-cols-12 2xl:gap-6 xl:gap-6 lg:gap-6 md:gap-4 mt-3 w-auto">
                <div className="col-span-12 2xl:col-span-6 xl:col-span-6 lg:col-span-6">
                  <label
                    className="block font-medium leading-6 text-gray-900 mb-2"
                    htmlFor="regular-form-12"
                  >
                    Upload photos
                  </label>
                  <p className="text-sm my-2 text-info">
                    {formik?.values?.workingImage?.name || ""}
                  </p>
                  {formik.values.workingImage ? (
                    <div className="border border-dashed border-2 border-[#D9D9D9] rounded-md p-4 flex justify-center items-center">
                      {formik?.values.workingImage?.type?.split("/")?.pop() ===
                        "jpeg" ||
                      formik?.values.workingImage?.type?.split("/")?.pop() ===
                        "png" ||
                      formik?.values.workingImage?.type?.split("/")?.pop() ===
                        "svg" ||
                      formik?.values.workingImage?.type?.split("/")?.pop() ===
                        "png" ||
                      formik?.values.workingImage?.type?.split("/")?.pop() ===
                        "jpg" ||
                      formik?.values.workingImage?.type?.split("/")?.pop() ===
                        "gif" ? (
                        <div className="cursor-pointer image-fit">
                          <div className="m-2 relative">
                            <img
                              alt="document"
                              className="rounded-lg h-[300px] w-full"
                              src={
                                formik?.values?.workingImage
                                  ? URL.createObjectURL(
                                      formik.values.workingImage
                                    )
                                  : noImage
                              }
                            />
                            <button
                              className="absolute -right-2 -top-1 h-5 w-5 bg-[#fff] rounded-full flex justify-center items-center"
                              onClick={handleRemoveImage}
                              type="button"
                            >
                              <MdClose className="w-4 h-4 font-bold" />
                            </button>
                          </div>
                        </div>
                      ) : (
                        <div className="cursor-pointer image-fit">
                          <div className="m-2 relative">
                            <img
                              alt="document"
                              className="rounded-lg h-20 w-20"
                              src={fileImg}
                            />
                            <button
                              className="absolute -right-2 -top-1 h-5 w-5 bg-[#fff] rounded-full flex justify-center items-center"
                              onClick={handleRemoveImage}
                              type="button"
                            >
                              <MdClose className="w-4 h-4 font-bold" />
                            </button>
                          </div>
                        </div>
                      )}
                    </div>
                  ) : (
                    <div className="h-52 rounded-md border border-dashed border-2 border-[#D9D9D9] text-center flex justify-center items-center">
                      <div>
                        <h6 className="font-bold text-sm">
                          Drag and Drop Image & Upload Image
                        </h6>
                        <div className="mt-6">
                          <label
                            htmlFor="workingImage"
                            className="py-2.5 px-5 text-sm rounded-md border border-dashed border-2 border-[#D9D9D9] text-secondary"
                          >
                            Select file
                            <input
                              id="workingImage"
                              type="file"
                              name="workingImage"
                              accept="image/*"
                              className="w-1 h-1 opacity-0"
                              onChange={(event) => {
                                formik.setFieldValue(
                                  "workingImage",
                                  event.currentTarget.files[0]
                                );
                              }}
                              onBlur={formik.handleBlur}
                            />
                          </label>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              {formik.touched.workingImage && formik.errors.workingImage ? (
                <div className="text-danger my-1 text-sm">
                  {formik.errors.workingImage}
                </div>
              ) : null}
              <div className="mt-3">
                <label
                  className="block font-medium leading-6 text-gray-900 mb-2"
                  htmlFor="regular-form-2"
                >
                  Description
                </label>
                <textarea
                  id="regular-form-2"
                  name="description"
                  type="text"
                  placeholder="Brief description of required work"
                  rows="4"
                  className="focus:outline-none py-3 px-4 block min-w-full xl:min-w-[350px] w-full rounded-xl border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-[#D9D9D9] placeholder:text-[#5A5A5A] placeholder:text-sm focus:ring-2 focus:ring-inset focus:ring-primary sm:text-sm sm:leading-6"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.description}
                ></textarea>
                {formik.touched.description && formik.errors.description ? (
                  <div className="text-danger my-1 text-sm">
                    {formik.errors.description}
                  </div>
                ) : null}
              </div>
              <div className="mt-3">
                <label
                  className="block text-sm font-medium leading-6 text-gray-900 mb-2"
                  htmlFor="regular-form-5"
                >
                  City
                </label>
                <Select
                  components={animatedComponents}
                  options={city_options}
                  getOptionLabel={(option) => option.label}
                  getOptionValue={(option) => option.value}
                  name="city"
                  value={formik.values.city}
                  onChange={(value) => formik.setFieldValue("city", value)}
                  onBlur={() => formik.setFieldTouched("city", true)}
                  placeholder="Select City"
                  className={`custom-select ${
                    formik.touched.city && formik.errors.city ? "invalid" : ""
                  }`}
                />
                {formik.touched.city && formik.errors.city ? (
                  <div className="text-danger my-1 text-sm">
                    {formik.errors.city}
                  </div>
                ) : null}
              </div>
              <div className="mt-3">
                <label
                  className="block font-medium leading-6 text-gray-900 mb-2"
                  htmlFor="regular-form-2"
                >
                  Address
                </label>
                <textarea
                  id="regular-form-2"
                  name="address"
                  type="text"
                  placeholder="Enter your address"
                  rows="4"
                  className="focus:outline-none py-3 px-4 block min-w-full xl:min-w-[350px] w-full rounded-xl border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-[#D9D9D9] placeholder:text-[#5A5A5A] placeholder:text-sm focus:ring-2 focus:ring-inset focus:ring-primary sm:text-sm sm:leading-6"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.address}
                ></textarea>
                {formik.touched.address && formik.errors.address ? (
                  <div className="text-danger my-1 text-sm">
                    {formik.errors.address}
                  </div>
                ) : null}
              </div>
              <div className="mt-3">
                <div className="flex gap-3">
                  <Checkbox
                    id="horizontal-list-react1"
                    color="indigo"
                    name="selection"
                    value="now"
                    checked={formik.values.selection === "now"}
                    onChange={() => formik.setFieldValue("selection", "now")}
                    label={
                      <Typography color="black" className="flex font-medium">
                        Now
                      </Typography>
                    }
                  />
                  <Checkbox
                    id="horizontal-list-react2"
                    color="indigo"
                    name="selection"
                    value="later"
                    checked={formik.values.selection === "later"}
                    onChange={() => formik.setFieldValue("selection", "later")}
                    label={
                      <Typography color="black" className="flex font-medium">
                        Later
                      </Typography>
                    }
                  />
                </div>
                {formik.errors.selection ? (
                  <div className="text-danger my-1 text-sm">
                    {formik.errors.selection}
                  </div>
                ) : null}
                {formik.values.selection === "later" ? (
                  <p className="my-2 text-[#000000]">
                    Please select your time and date when you require the
                    service one of our Fixers will be with you on you selected
                    time and date
                  </p>
                ) : (
                  ""
                )}
              </div>
              <div className="mt-3 font-medium text-primary">
                Estimate Cost : £{formik?.values?.price || 0}
              </div>
              {formik.values.selection === "later" ? (
                <div className="2xl:py-6 xl:py-6 lg:py-6 py-4">
                  <div className="grid grid-cols-12 2xl:gap-6 xl:gap-6 lg:gap-6 md:gap-4">
                    <div className="col-span-12 2xl:col-span-6 xl:col-span-6 lg:col-span-6 md:col-span-6 w-full">
                      <div className="table-row-shadow 2xl:p-4 xl:p-4 lg:p-4 p-2 rounded-[20px] w-auto">
                        <DayPicker
                          mode="single"
                          disabled={disabledDays}
                          selected={formik.values.bookingDate}
                          onSelect={handleDayClick}
                          showOutsideDays
                          className="border-0"
                          required={true}
                          classNames={{
                            caption:
                              "flex justify-center py-4 mb-4 relative items-center border-b-2",
                            caption_label: "text-sm font-medium text-gray-900",
                            nav: "flex items-center",
                            nav_button:
                              "h-6 w-6 bg-transparent hover:bg-blue-gray-50 p-1 rounded-full transition-colors duration-300",
                            nav_button_previous: "absolute left-1.5",
                            nav_button_next: "absolute right-1.5",
                            table: "w-full border-collapse",
                            head_row: "flex font-medium text-gray-900",
                            head_cell:
                              "2xl:m-0.5 xl:m-0.5 lg:m-0.5 md:m-0.5 w-full font-normal text-sm",
                            row: "flex w-full mt-2",
                            cell: "text-gray-600 rounded-full 2xl:h-9 xl:h-9 lg:h-9 md:h-8 h-7 w-full text-center text-sm p-0 m-0.5 xl:m-0.5 lg:m-0.5 md:m-0.5 relative [&:has([aria-selected].day-range-end)]:rounded-r-md [&:has([aria-selected].day-outside)]:bg-transperent [&:has([aria-selected].day-outside)]:text-white [&:has([aria-selected])]:bg-transperent first:[&:has([aria-selected])]:rounded-l-md last:[&:has([aria-selected])]:rounded-r-md focus-within:relative focus-within:z-20",
                            day: "2xl:h-9 2xl:w-9 xl:h-9 xl:w-9 lg:h-9 lg:w-9 md:h-8 md:w-8 h-7 w-7 p-0 font-normal",
                            day_range_end: "day-range-end",
                            day_selected:
                              "rounded-full bg-primary text-white hover:bg-primary hover:text-white focus:bg-primary focus:text-white",
                            day_today:
                              "border border-primary text-black rounded-full",
                            day_outside:
                              "day-outside text-gray-500 opacity-50 aria-selected:bg-gray-500 aria-selected:text-gray-900 aria-selected:bg-opacity-10",
                            day_disabled: "text-gray-500 opacity-50",
                            day_hidden: "invisible",
                          }}
                          components={{
                            IconLeft: ({ ...props }) => (
                              <FaCircleChevronLeft
                                {...props}
                                className="2xl:h-6 2xl:w-6 xl:h-6 xl:w-6 h-5 w-5 stroke-2"
                                color="#37489B"
                              />
                            ),
                            IconRight: ({ ...props }) => (
                              <FaCircleChevronRight
                                {...props}
                                className="2xl:h-6 2xl:w-6 xl:h-6 xl:w-6 h-5 w-5 stroke-2"
                                color="#37489B"
                              />
                            ),
                          }}
                        />
                      </div>
                      {formik.touched.bookingDate &&
                      formik.errors.bookingDate ? (
                        <div className="text-danger my-1 text-sm">
                          {formik.errors.bookingDate}
                        </div>
                      ) : null}
                    </div>
                    <div className="col-span-12 2xl:col-span-6 xl:col-span-6 lg:col-span-6 md:col-span-6">
                      <div className="2xl:p-4 xl:p-4 lg:p-4 md:p-4 py-5">
                        <h5 className="text-lg">Select time</h5>
                        <div className="py-3 h-[323px] overflow-auto">
                          <div className="grid grid-cols-12 2xl:gap-6 xl:gap-6 lg:gap-6 md:gap-4 gap-3">
                            {time_arr.map((item, index) => {
                              return (
                                <div
                                  key={index}
                                  className="col-span-6 2xl:col-span-3 xl:col-span-4 lg:col-span-4 md:col-span-6"
                                >
                                  <div
                                    onClick={(e) => handleTimeClick(item.value)}
                                    className={`2xl:text-base xl:text-base lg:text-base md:text-sm text-sm cursor-pointer rounded-lg text-center 2xl:py-3 xl:py-3 py-2 2xl:px-6 xl:px-6 px-5 ${
                                      selectTime === item.value
                                        ? "bg-primary text-white"
                                        : "border border-primary"
                                    }`}
                                  >
                                    <h5 className="2xl:text-base xl:text-base lg:text-sm md:text-base text-sm">
                                      {item.name}
                                    </h5>
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                        {formik.touched.bookingTime &&
                        formik.errors.bookingTime ? (
                          <div className="text-danger my-1 text-sm">
                            {formik.errors.bookingTime}
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}
              <div className="2xl:py-5 xl:py-5 lg:py-5 md:py-3 py-2 flex 2xl:justify-end xl:justify-end lg:justify-end md:justify-center items-center justify-center">
                <Button
                  className="flex items-center justify-center capitalize bg-primary text-base font-normal 2xl:min-w-[380px] xl:min-w-[360px] lg:min-w-[340px] md:min-w-[320px] min-w-[260px]"
                  type="submit"
                  disabled={loader}
                >
                  <span>Submit</span>{" "}
                  {loader ? <Spinner className="h-4 w-4 ml-2" /> : ""}
                </Button>
              </div>
            </form>
          </div>
        </div>
      </div>

      <Dialog
        open={open}
        handler={handleOpen}
        size="sm"
        className="rounded-none 2xl:min-w-[35%] xl:min-w-[35%] xl:max-w-[40%] lg:min-w-[40%] md:min-w-[50%] min-w-[90%]"
      >
        <DialogBody>
          <div className="my-4 flex justify-center items-center">
            <Lottie options={defaultOptions} height={144} width={144} />
          </div>
          <div className="my-3">
            <h6 className="text-primary font-medium text-center 2xl:text-xl xl:text-xl lg:text-xl text-lg">
              Your Booking Request sent
            </h6>
            <h4 className="text-primary font-semibold text-center text-2xl py-3">
              Successful!
            </h4>
          </div>
          <div className="flex justify-center items-center py-5">
            <Button
              className="text-sm py-3 rounded-xl w-[50%] capitalize font-medium bg-primary"
              onClick={(e) => handleSubmit(e)}
            >
              Done
            </Button>
          </div>
        </DialogBody>
      </Dialog>
    </>
  );
};

export default BookService;
