import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { loginbg } from "../../assets/images/base64/loginbg";
// import logo from "../../assets/images/logo/logo.png";
import logo from "../../assets/images/home/logo-lg.png";
import { Button, Radio, Spinner, Typography } from "@material-tailwind/react";
import noImage from "../../assets/images/no-image.png";
import cameraIcon from "../../assets/images/icons/cameraIcons.png";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { useFormik } from "formik";
import * as yup from "yup";
import { accountSetupApi } from "../../services/ApiServices";
import toast from "react-hot-toast";

const AccountSetup = () => {
  const location = useLocation();

  var Usertoken = "";

  try {
    const { user_token } = location.state;
    Usertoken = user_token;
  } catch (e) {
    //id not found
  }

  const navigate = useNavigate();
  const [countryCode, setCountryCode] = useState("");
  const [isoCode, setIsoCode] = useState("");
  const [loader, setLoader] = useState(false);

  const validationSchema = yup.object().shape({
    profilePic: yup
      .mixed()
      .required("Profile Picture is required")
      .test("fileFormat", "Only Image files are allowed", (value) => {
        if (value) {
          const supportedFormats = ["jpg", "jpeg", "png"];
          return supportedFormats.includes(
            value.name.split(".").pop().toLowerCase()
          );
        }
        return true;
      }),
    firstName: yup
      .string()
      .max(36, "First Name Must be 36 characters or less")
      .required("Please enter a Your First Name"),
    lastName: yup
      .string()
      .max(36, "Last Name Must be 36 characters or less")
      .required("Please enter a Your Last Name"),
    phoneNumber: yup.string().required("Phone Number is required"),
    streetAddress: yup.string().required("Street Address is required"),
    gender: yup
      .string()
      .oneOf(["male", "female", "other"], "Please select a valid gender")
      .required("Gender is required"),
  });

  const formik = useFormik({
    initialValues: {
      profilePic: "",
      firstName: "",
      lastName: "",
      phoneNumber: "",
      streetAddress: "",
      gender: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values, { resetForm }) => {
      setLoader(true);
      const rawPhone = values.phoneNumber.replace(isoCode, "");

      var formdata = new FormData();
      formdata.append("profilePic", values.profilePic);
      formdata.append("firstName", values.firstName);
      formdata.append("lastName", values.lastName);
      formdata.append("streetAddress", values.streetAddress);
      formdata.append("gender", values.gender);
      formdata.append("countryCode", `+${isoCode}`);
      formdata.append("isoCode", countryCode);
      formdata.append("phoneNumber", `${rawPhone}`);

      accountSetupApi(formdata, Usertoken)
        .then((res) => {
          const message = res.data.message;
          const info = res.data.info;
          setLoader(false);

          toast.success(message || "Create your account Successfully!!!");
          localStorage.setItem("user_token", Usertoken);
          localStorage.setItem("user_info", JSON.stringify(info));
          localStorage.removeItem("UserToken");
          navigate("/");
          resetForm();
        })
        .catch((err) => {
          const message = err.response.data.message;
          setLoader(false);
          console.log("err >>>", err);
          toast.error(message || "Somthing went wrong!!");
        });
    },
  });

  return (
    <>
      <div className="grid grid-cols-12 gap-3">
        <div className="col-span-12 2xl:col-span-6 xl:col-span-6 lg:col-span-6 w-full 2xl:block xl:block lg:block hidden h-screen">
          <img
            src={loginbg}
            className="object-cover w-full h-full"
            style={{ objectPosition: "100% 70%" }}
            alt=""
          ></img>
        </div>
        <div className="col-span-12 2xl:col-span-6 xl:col-span-6 lg:col-span-6 flex justify-center items-center">
          <div className="justify-center items-center flex w-full">
            <div className="2xl:py-10 xl:py-0 lg:py-8 md:py-8 py-6 mx-8 2xl:min-w-96 xl:min-w-80 lg:min-w-80 md:min-w-80 min-w-72 w-auto">
              <div className="flex justify-center 2xl:py-10 py-5 2xl:hidden xl:hidden lg:hidden block">
                <img src={logo} className="h-[70px]" alt="" />
              </div>
              <div className="my-4 text-center">
                <h1 className="2xl:text-3xl xl:text-2xl text-xl font-medium">
                  Personal Information
                </h1>
              </div>
              <div className="py-4">
                <form onSubmit={formik.handleSubmit} className="">
                  <div className="justify-center items-center flex">
                    <label htmlFor="profile">
                      <div className="relative 2xl:w-[120px] 2xl:h-[120px] w-24 h-24">
                        <img
                          id="profilePic"
                          alt="profile_pic"
                          className="rounded-full object-cover w-full h-full"
                          src={
                            formik?.values?.profilePic
                              ? URL.createObjectURL(formik.values.profilePic)
                              : noImage
                          }
                        />
                        <input
                          id="profile"
                          type="file"
                          name="profilePic"
                          accept="image/*"
                          className="w-1 h-1 top-0 bottom-0 absolute opacity-0"
                          onChange={(event) => {
                            formik.setFieldValue(
                              "profilePic",
                              event.currentTarget.files[0]
                            );
                          }}
                          onBlur={formik.handleBlur}
                        />
                        <div className="absolute bottom-0 right-0 flex items-center justify-center p-2 mb-1 mr-1 rounded-full bg-primary">
                          <img
                            alt=""
                            src={cameraIcon}
                            className="w-4 h-4 text-white"
                          />
                        </div>
                      </div>
                      <div className="text-center py-2">
                        <p className="text-xs text-secondary">
                          Select Profile Photo
                        </p>
                      </div>
                    </label>
                  </div>
                  {formik.touched.profilePic && formik.errors.profilePic ? (
                    <div className="text-danger my-1 text-center text-sm">
                      {formik.errors.profilePic}
                    </div>
                  ) : null}
                  <div className="py-4">
                    <div className="">
                      <label
                        className="block text-sm font-medium leading-6 text-gray-900 mb-1"
                        htmlFor="regular-form-1"
                      >
                        First Name
                      </label>
                      <input
                        id="regular-form-1"
                        name="firstName"
                        type="text"
                        placeholder="Enter First Name"
                        className="focus:outline-none py-3 ps-4 pe-11 block min-w-full xl:min-w-[350px] w-full rounded-xl border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#37489B] sm:text-sm sm:leading-6"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.firstName}
                      />
                      {formik.touched.firstName && formik.errors.firstName ? (
                        <div className="text-danger my-1 text-sm">
                          {formik.errors.firstName}
                        </div>
                      ) : null}
                    </div>
                    <div className="mt-3">
                      <label
                        className="block text-sm font-medium leading-6 text-gray-900 mb-1"
                        htmlFor="regular-form-2"
                      >
                        Last Name
                      </label>
                      <div className="relative">
                        <input
                          id="regular-form-1"
                          name="lastName"
                          type="text"
                          placeholder="Enter Last Name"
                          className="focus:outline-none py-3 ps-4 pe-11 block min-w-full xl:min-w-[350px] w-full rounded-xl border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#37489B] sm:text-sm sm:leading-6"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.lastName}
                        />
                        {formik.touched.lastName && formik.errors.lastName ? (
                          <div className="text-danger my-1 text-sm">
                            {formik.errors.lastName}
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <div className="mt-3">
                      <label
                        className="block text-sm font-medium leading-6 text-gray-900 mb-1"
                        htmlFor="regular-form-9"
                      >
                        Phone Number <span className="text-danger">*</span>
                      </label>
                      <PhoneInput
                        name="phoneNumber"
                        country={"us"}
                        enableAreaCodes={true}
                        copyNumbersOnly
                        value={formik.values.phoneNumber}
                        onChange={(phone, event) => {
                          formik.setFieldValue("phoneNumber", phone);
                          setCountryCode(event.countryCode);
                          setIsoCode(event.dialCode);
                        }}
                      />
                      {formik.touched.phoneNumber &&
                        formik.errors.phoneNumber ? (
                        <div className="text-danger my-1 text-sm">
                          {formik.errors.phoneNumber}
                        </div>
                      ) : null}
                    </div>
                    <div className="mt-3">
                      <label
                        className="block text-sm font-medium leading-6 text-gray-900 mb-1"
                        htmlFor="regular-form-3"
                      >
                        Street Address <span className="text-danger">*</span>
                      </label>
                      <input
                        id="regular-form-3"
                        name="streetAddress"
                        type="text"
                        placeholder="Type here"
                        className="focus:outline-none py-3 px-4 block min-w-full xl:min-w-[350px] w-full rounded-xl border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-[#D9D9D9] placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary sm:text-sm sm:leading-6"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.streetAddress}
                      />
                      {formik.touched.streetAddress &&
                        formik.errors.streetAddress ? (
                        <div className="text-danger my-1 text-sm">
                          {formik.errors.streetAddress}
                        </div>
                      ) : null}
                    </div>
                    <div className="mt-2">
                      <label
                        className="block text-sm font-medium leading-6 text-gray-900 mb-1"
                        htmlFor="regular-form-9"
                      >
                        Gender
                      </label>
                      <div className="flex flex-wrap 2xl:gap-6 xl:gap-6 lg:gap-6 md:gap-4">
                        <Radio
                          name="gender"
                          color="indigo"
                          className="p-0"
                          value="male"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          checked={formik.values.gender === "male"}
                          label={
                            <div>
                              <Typography
                                color="blue-gray"
                                className="font-medium text-black"
                              >
                                Male
                              </Typography>
                            </div>
                          }
                        />
                        <Radio
                          name="gender"
                          color="indigo"
                          value="female"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          checked={formik.values.gender === "female"}
                          label={
                            <div>
                              <Typography
                                color="blue-gray"
                                className="font-medium text-black"
                              >
                                Female
                              </Typography>
                            </div>
                          }
                        />
                        <Radio
                          name="gender"
                          color="indigo"
                          value="other"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          checked={formik.values.gender === "other"}
                          label={
                            <div>
                              <Typography
                                color="blue-gray"
                                className="font-medium text-black"
                              >
                                Other
                              </Typography>
                            </div>
                          }
                        />
                      </div>
                      {formik.touched.gender && formik.errors.gender ? (
                        <div className="text-danger my-1 text-sm">
                          {formik.errors.gender}
                        </div>
                      ) : null}
                    </div>
                    <div className="my-6 text-center">
                      <Button
                        className="flex items-center justify-center w-full text-sm py-3 rounded-lg bg-primary capitalize font-normal"
                        type="submit"
                        disabled={loader}
                      >
                        <span>Done</span>{" "}
                        {loader ? <Spinner className="h-4 w-4 ml-2" /> : ""}
                      </Button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AccountSetup;
