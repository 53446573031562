import React from "react";
import { Link } from "react-router-dom";
import appstoreimg from "../../assets/images/newlanding/appstore.png";
import playstoreimg from "../../assets/images/newlanding/playstore.png";
import {
  FaFacebookF,
  FaInstagram,
  FaLinkedin,
  FaTwitter,
} from "react-icons/fa6";

const NewFooter = () => {
  return (
    <footer className="footer">
      <div className="main-container px-5">
        <div className="grid grid-cols-12 2xl:pb-5 xl:pb-5 lg:pb-5 md:pb-5 pb-2">
          <div className="col-span-12 2xl:col-span-4 xl:col-span-4 lg:col-span-4 md:col-span-6 flex flex-col quick-links-col 2xl:mb-0 xl:mb-0 lg:mb-0 mb-3 2xl:pt-4 xl:pt-4 lg:pt-4 pt-4">
            <div className="content">
              <h4 className="heading text-2xl">Quick Links</h4>
              <ul className="list">
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li>
                  <Link to="/services">Our Services</Link>
                </li>
                <li>
                  <Link to="/howitworks">How It Works</Link>
                </li>
                <li>
                  <Link to="/costguide">Cost Guide</Link>
                </li>
                <li>
                  <a href="https://gofixitnow.online/fixer/signin">
                    Become a Fixer
                  </a>
                </li>
                <li>
                  <Link to="/signin">Login</Link>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-span-12 2xl:col-span-4 xl:col-span-4 lg:col-span-4 md:col-span-6 flex flex-col align-items-lg-center contact-info-col mb-lg-0 mb-3 pt-md-4">
            <div className="content">
              <h4 className="heading text-2xl">Contact Info</h4>
              <div className="contact-box pt-0">
                <div className="icon">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="200"
                    height="200"
                    fill="currentColor"
                    className="bi bi-telephone"
                    viewBox="0 0 16 16"
                    id="IconChangeColor"
                  >
                    {" "}
                    <path
                      d="M3.654 1.328a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.568 17.568 0 0 0 4.168 6.608 17.569 17.569 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.678.678 0 0 0-.58-.122l-2.19.547a1.745 1.745 0 0 1-1.657-.459L5.482 8.062a1.745 1.745 0 0 1-.46-1.657l.548-2.19a.678.678 0 0 0-.122-.58L3.654 1.328zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z"
                      id="mainIconPathAttribute"
                    ></path>{" "}
                  </svg>
                </div>
                <div className="desc">
                  <p className="title">Phone Number</p>
                  <Link to="tel:+448000016735">0800 001 6735</Link>
                </div>
              </div>
              <hr />
              <div className="contact-box">
                <div className="icon">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-envelope"
                    viewBox="0 0 16 16"
                  >
                    {" "}
                    <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4Zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1H2Zm13 2.383-4.708 2.825L15 11.105V5.383Zm-.034 6.876-5.64-3.471L8 9.583l-1.326-.795-5.64 3.47A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.741ZM1 11.105l4.708-2.897L1 5.383v5.722Z"></path>{" "}
                  </svg>
                </div>
                <div className="desc">
                  <p className="title">Email Address</p>
                  <Link to="mailto:info@gofixitnow.co.uk">
                    info@gofixitnow.co.uk
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="col-span-12 2xl:col-span-4 xl:col-span-4 lg:col-span-4 md:col-span-6 flex flex-col align-items-lg-end stores-and-social-meida-icons-col mb-lg-0 mb-md-3 mb-5 pt-md-4">
            <div className="content">
              <img
                src={appstoreimg}
                alt="app_store img"
                className="store-img"
              />
              <img
                src={playstoreimg}
                alt="play_store img"
                className="store-img"
              />
              <div className="flex items-center gap-1.5">
                <div className="bg-white rounded-md p-2">
                  <FaFacebookF fontSize={18} color="#31459a" />
                </div>
                <div className="bg-white rounded-md p-2">
                  <FaTwitter fontSize={18} color="#31459a" />
                </div>{" "}
                <div className="bg-white rounded-md p-2">
                  <FaInstagram fontSize={18} color="#31459a" />
                </div>{" "}
                <div className="bg-white rounded-md p-2">
                  <FaLinkedin fontSize={18} color="#31459a" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="bottom-footer text-center py-4">
          <p>
            © Go Fix It Now 2024 |{" "}
            <Link to="/privacypolicy">Privacy Policy</Link> |{" "}
            <Link to="/termsandconditions">Terms Of Use</Link>
          </p>
        </div>
      </div>
    </footer>
  );
};

export default NewFooter;
